import { env } from 'src/env';

export const feedbackBaseUrl = env.REACT_APP_NINJA_FEEDBACK_API_URL;
export const tasksBaseUrl = env.REACT_APP_NINJA_API_V2_URL;
export const guestUsersBaseUrl = `${env.REACT_APP_NINJA_API_V2_URL}/users/guest`;
export const contactsBaseUrl = env.REACT_APP_CONTACTS_ENDPOINT;
export const tokenBaseUrl = env.REACT_APP_NINJA_TOKEN_API_URL;
export const jsonBaseUrl = env.REACT_APP_S3_BUCKET;
export const combusBaseUrl = env.REACT_APP_COMBUS_ENDPOINT;
export const combusWSBaseUrl = env.REACT_APP_COMBUS_WS_ENDPOINT;
export const metahumanBaseUrl = env.REACT_APP_META_HUMAN_BASE_URL;
export const matchmakerBaseUrl = env.REACT_APP_AVATAR_MATCHMAKER_SERVER;
export const walletBaseUrl = env.REACT_APP_NINJA_WALLET_API_URL;
export const contentBaseUrl = env.REACT_APP_NINJA_CONTENT_API_URL;
export const bffTasksBaseUrl = env.REACT_APP_BFF_URL;
