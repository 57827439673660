import { useEffect, useState } from 'react';
import { Header } from 'src/pages/AuthPages/components/Header';
import atlasSmile from 'src/images/login/atlas_smile.png';
import { FormInput } from 'src/components/FormInput';
import { FormProvider } from 'src/components/FormProvider';
import { ServiceError } from '@aws-amplify/core/src/types/errors';
import { useForm } from 'react-hook-form';
import { LoginHeader } from 'src/pages/AuthPages/components/LoginHeader';
import { PasswordHint } from 'src/pages/AuthPages/components/PasswordHint';
import { useNavigate } from 'react-router-dom';
import { confirmResetPassword } from 'aws-amplify/auth';
import { ErrorMessageBlock } from 'src/pages/AuthPages/components/ErrorMessageBlock';
import dayjs from 'dayjs';
import { useQueryParams } from 'src/hooks';

enum ResetPasswordFields {
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirm_password',
}

interface FormData {
  [ResetPasswordFields.PASSWORD]: string;
  [ResetPasswordFields.CONFIRM_PASSWORD]: string;
}

export const ResetPasswordPage = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { searchParams } = useQueryParams();
  const { username } = searchParams;
  const confirmationCode = searchParams.code;
  const expiredAt = searchParams.expired_at;

  useEffect(() => {
    if (dayjs.unix(Number(expiredAt)).isBefore(dayjs())) {
      navigate(`/password-reset-failed?username=${username}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiredAt]);

  const methods = useForm<FormData>({
    defaultValues: {
      [ResetPasswordFields.PASSWORD]: '',
      [ResetPasswordFields.CONFIRM_PASSWORD]: '',
    },
  });

  const { handleSubmit } = methods;

  const submitForm = async (data: FormData) => {
    try {
      if (
        data[ResetPasswordFields.PASSWORD] !==
        data[ResetPasswordFields.CONFIRM_PASSWORD]
      ) {
        setErrorMessage('Passwords do not match. Please try again.');
        return;
      } else if (!username || !confirmationCode) {
        return;
      }
      await confirmResetPassword({
        username,
        confirmationCode,
        newPassword: data[ResetPasswordFields.PASSWORD],
      });
      navigate('/login-with-email?password-changed=true');
      setErrorMessage('');
    } catch (error) {
      switch ((error as ServiceError).name) {
        case 'InvalidPasswordException':
          setErrorMessage(
            'Password does not meet required criteria. Please review and try again.',
          );
          return;
        case 'ExpiredCodeException':
          navigate(`/password-reset-failed?username=${username}`);
          return;
        default:
          setErrorMessage(error ? (error as ServiceError).message : 'error');
          return;
      }
    }
  };

  return (
    <div className="nj-auth-layout nj-beta">
      <Header rightSide={<LoginHeader />} />
      <main className="nj-auth-content">
        <h3 className="nj-auth-header-secondary">Reset your password</h3>
        <FormProvider<FormData> methods={methods}>
          <form onSubmit={handleSubmit(submitForm)} className="nj-auth-form">
            <div className="nj-auth-form--field">
              <legend className="nj-auth-form--legend">
                Password <PasswordHint />
              </legend>
              <FormInput
                name={ResetPasswordFields.PASSWORD}
                type="password"
                className="nj-auth-form--input"
                placeholder="Create Password"
                required={true}
                hasErrorBorder={errorMessage.length > 0}
              />
            </div>
            <div className="nj-auth-form--field">
              <legend className="nj-auth-form--legend">Confirm password</legend>
              <FormInput
                name={ResetPasswordFields.CONFIRM_PASSWORD}
                type="password"
                className="nj-auth-form--input"
                placeholder="Confirm Password"
                required={true}
                hasErrorBorder={errorMessage.length > 0}
              />
            </div>
            <button className="nj-auth-form--submit-button" type="submit">
              Reset password
            </button>
            {errorMessage.length > 0 && (
              <ErrorMessageBlock errorMessage={errorMessage} />
            )}
          </form>
        </FormProvider>
      </main>
      <img className="nj-auth-background-image" src={atlasSmile} alt="Ninja" />
    </div>
  );
};
