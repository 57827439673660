import { useAvatarPreferences } from 'src/hooks';
import ninjaIcon from 'src/images/loader/ninja-head-new.png';

type NinjaLoaderProps = {
  content: string;
};

const IMG_SIZE = 160;

export const NinjaLoader = ({ content }: NinjaLoaderProps) => {
  const { avatarImageURL } = useAvatarPreferences();

  if (!avatarImageURL) {
    return (
      <div className="nj-ninja-loader">
        <div className="nj-ninja-loader--images">
          <div className="nj-ninja-loader--icon-wrapper">
            <img src={ninjaIcon} alt="Ninja" width={120} height={120} />
          </div>

          <div className="nj-ninja-loader--spinner"></div>
        </div>
        <div className="nj-ninja-loader--content">{content}</div>
      </div>
    );
  }

  return (
    <div className="nj-ninja-loader">
      <div className="nj-ninja-loader--images">
        <img
          src={avatarImageURL}
          alt="Ninja"
          className="nj-ninja-loader--image"
          width={IMG_SIZE}
          height={IMG_SIZE}
        />

        <div className="nj-ninja-loader--spinner"></div>
      </div>
      <div className="nj-ninja-loader--content">{content}</div>
    </div>
  );
};
