import { useEffect } from 'react';
import { useSession, useUserMetadata } from 'src/hooks';
import { HintTooltip } from 'src/v2/commonComponents/HintTooltip';
import styles from './ExternalModelHint.module.scss';

interface ExternalModelHintProps {
  id: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const ExternalModelHint = ({
  id,
  isOpen,
  onOpen,
  onClose,
}: ExternalModelHintProps) => {
  const { isOpenTier, appUser } = useSession();

  const { metadata } = useUserMetadata();

  useEffect(() => {
    if (
      metadata &&
      !metadata.external_model_onboarded &&
      !isOpenTier &&
      appUser.user_id
    ) {
      onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata, isOpenTier, appUser.user_id]);

  return (
    <HintTooltip
      id={id}
      title="See what’s new!"
      content="Choose and compare outputs from Ninja and external models to find the response that aligns best with your goals."
      isOpen={isOpen}
      onClose={onClose}
      className={styles.root}
    />
  );
};
