import { Link } from 'react-router-dom';
import { useBreakpoint } from 'src/hooks';
import { AppRoutes } from 'src/types';
import { isEnterprise } from 'src/utils';

export const SignupHeader = () => {
  const { isMobile } = useBreakpoint();

  return (
    <div className="nj-auth-header-right-side">
      {!isMobile && (
        <span>
          {isEnterprise
            ? 'Already have an account?'
            : 'Already have a Beta account?'}
        </span>
      )}
      <Link
        to={AppRoutes.LOGIN}
        className="nj-button nj-auth-sign-in-up-button nj-auth-login-button"
      >
        Log in
      </Link>
    </div>
  );
};
