import styles from './TranslatePromptButton.module.scss';
import { useContext, useRef, useState } from 'react';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { ArrowUUpLeft, Translate } from '@phosphor-icons/react';
import { PromptLoadingReason, SVG_SIZE_M, SVG_SIZE_S } from 'src/constants';
import { useBreakpoint, useSession } from 'src/hooks';
import log from 'loglevel';
import { Spinner } from 'src/components/Loading';
import { getBaseHeaders } from 'src/store/services/config';
import { bffTasksBaseUrl } from 'src/store/constants';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { Modal } from 'react-responsive-modal';
import { TextList } from 'src/v2/commonComponents/TextList';
import { Button } from 'src/v2/commonComponents/Button';
import { BottomDrawer } from 'src/components/BottomDrawer';
import { X } from '@phosphor-icons/react';
import { useGetTranslationLanguagesJSONQuery } from 'src/store/services';
import { IconButton } from 'src/v2/components/IconButton';

type TranslatePromptButtonProps = {
  isDisabled?: boolean;
};

const textDecoder = new TextDecoder();

export const TranslatePromptButton = ({
  isDisabled = false,
}: TranslatePromptButtonProps) => {
  const { threadInputBoxRef } = useContext(ForwardRefContext);
  const { appUser } = useSession();
  const { isMobile } = useBreakpoint();
  const {
    threadInputBoxValue,
    setTemporaryInputValue,
    setPromptLoading,
    setPromptModificationReason,
    temporaryInputValue,
    setThreadInputBoxValue,
    promptLoading,
    promptModificationReason,
  } = useContext(ThreadInputBoxContext);
  const undoTriggerRef = useRef(false);

  const { isMobileOrTablet } = useBreakpoint();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [translatedText, setTranslatedText] = useState('');

  // Load languages using the query
  const { data: languages = {}, isLoading } =
    useGetTranslationLanguagesJSONQuery();

  const defaultLanguage = 'en';
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);
  const lastUsedLanguage = useRef(defaultLanguage);

  const handleReturnInputToFocus = () => {
    threadInputBoxRef?.current?.focus();
  };

  const updatePrompt = async (response: Response, stream: boolean) => {
    let value = '';
    if (stream) {
      if (undoTriggerRef.current) {
        undoTriggerRef.current = false;
        return;
      }
      if (response.body) {
        const reader = response.body.getReader();
        while (true) {
          const { done, value: chunk } = await reader.read();
          if (done) break;
          value += textDecoder.decode(chunk, { stream: true });
          if (undoTriggerRef.current) {
            undoTriggerRef.current = false;
            return;
          }
          setThreadInputBoxValue(value);
        }
        setTranslatedText(value);
      }
    } else {
      const result = await response.json();
      setThreadInputBoxValue(result.translated_prompt);
      setTranslatedText(result.translated_prompt);
    }
  };

  const handleSendPrompt = async (language = selectedLanguage) => {
    try {
      setPromptLoading(true);
      setPromptModificationReason(PromptLoadingReason.TRANSLATING);
      setTemporaryInputValue(threadInputBoxValue);
      setThreadInputBoxValue('');

      await fetch(
        `${bffTasksBaseUrl}/users/${appUser.user_id}/prompts/translate`,
        {
          method: 'POST',
          headers: await getBaseHeaders(
            new Headers({
              'Content-Type': 'application/json',
            }),
          ),
          body: JSON.stringify({
            prompt: threadInputBoxValue,
            target_language: language,
            stream: true,
          }),
        },
      )
        .then((response) => updatePrompt(response, true))
        .catch((err) => log.error(err));

      setPromptLoading(false);
    } catch (e) {
      if (undoTriggerRef.current) {
        undoTriggerRef.current = false;
      }
      setPromptLoading(false);
      setThreadInputBoxValue(temporaryInputValue);
      setTemporaryInputValue('');
      log.error(e);
    }
    handleReturnInputToFocus();
  };

  const handleUndo = () => {
    if (promptLoading) undoTriggerRef.current = true;
    setPromptLoading(false);
    setThreadInputBoxValue(temporaryInputValue);
    setTemporaryInputValue('');
    handleReturnInputToFocus();
  };

  const handleLanguageSelect = (id: string) => {
    setSelectedLanguage(id);
    lastUsedLanguage.current = id;
  };

  const renderLanguageList = () =>
    isMobileOrTablet ? renderBottomDrawer() : renderTranslationModal();

  const RenderList = () => (
    <div className={styles.root}>
      <div className={styles.header}>
        <p className={styles.title}>Translation options</p>
        <p className={styles.subtitle}>Choose a language for your text</p>

        <Button
          className={styles.closeButton}
          shape="round"
          color="transparent"
          onClick={() => setIsModalOpen(false)}
        >
          <X size={SVG_SIZE_S} />
        </Button>

        <div
          style={{
            maxHeight: '60vh',
            overflowY: 'auto',
            paddingRight: '8px',
          }}
        >
          {isLoading ? (
            <Spinner size={SVG_SIZE_M} inline />
          ) : (
            <TextList
              values={languages}
              defaultValue={defaultLanguage}
              onValueSelect={handleLanguageSelect}
            />
          )}
        </div>

        <div>
          <hr className="divider" />
          <div className={styles.buttonDrawer}>
            <Button
              disabled={false}
              color="secondary"
              className={styles.button}
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              disabled={false}
              className={styles.button}
              onClick={() => {
                setIsModalOpen(false);
                handleSendPrompt(selectedLanguage);
              }}
            >
              Translate
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  const renderBottomDrawer = () => (
    <BottomDrawer
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      className={styles.drawer}
    >
      <RenderList />
    </BottomDrawer>
  );

  const renderTranslationModal = () => (
    <Modal
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      classNames={{
        root: 'nj-modal--root',
        overlay: 'nj-modal--overlay',
        modal: 'nj-modal--access',
      }}
      center
    >
      <RenderList />
    </Modal>
  );

  // Cancel
  if (
    promptModificationReason === PromptLoadingReason.TRANSLATING &&
    promptLoading
  ) {
    return (
      <>
        <IconButton
          type="button"
          onClick={handleUndo}
          customContent={<Spinner size={SVG_SIZE_M} inline />}
          size={SVG_SIZE_M}
          isMobile={isMobile}
          label="Cancel"
        />
      </>
    );
  }

  // Undo
  if (
    promptModificationReason === PromptLoadingReason.TRANSLATING &&
    !!temporaryInputValue &&
    threadInputBoxValue.trim() === translatedText.trim()
  ) {
    return (
      <>
        <IconButton
          type="button"
          onClick={handleUndo}
          customContent={<ArrowUUpLeft size={SVG_SIZE_M} />}
          size={SVG_SIZE_M}
          isMobile={isMobile}
          label="Undo"
        />
      </>
    );
  }

  return (
    <>
      <IconButton
        className={styles.translatePromptButton}
        icon={Translate}
        label="Translate"
        size={SVG_SIZE_M}
        onClick={() => {
          setSelectedLanguage(defaultLanguage); // Reset to default language
          setIsModalOpen(true);
        }}
        disabled={isDisabled || !threadInputBoxValue.trim()}
        isMobile={isMobile}
      />
      {renderLanguageList()}
    </>
  );
};
