import styles from './MainSignupSection.module.scss';
import { SSOButton } from 'src/v2/pages/auth/components/SSOButton/SSOButton';
import { AppRoutes, GTMEvent, SSOProvider } from 'src/types';
import { useCallback, useContext, useState } from 'react';
import AuthContext from 'src/contexts/AuthContext';
import { EmailSubmitForm } from 'src/v2/pages/auth/components/EmailSubmitForm';
import { Link, useNavigate } from 'react-router-dom';
import { SignupFeaturesSection } from 'src/v2/pages/auth/components/SignupFeaturesSection/SignupFeaturesSection';
import { sendGTMEvent } from 'src/utils';
import { isGoogleEmailProvider } from 'src/utils/user';
import { useLazyCheckEmailInfoQuery } from 'src/store/services';
import { GoogleSignupSuggestionSection } from 'src/v2/pages/auth/components/GoogleSignupSuggestionSection';
import { TermsSection } from 'src/v2/pages/auth/components/TermsSection/TermsSection';

export const MainSignupSection = () => {
  const navigate = useNavigate();
  const { setAuthLoading } = useContext(AuthContext);

  const [googlePromptEmail, setGooglePromptEmail] = useState('');
  const [checkEmailInfo] = useLazyCheckEmailInfoQuery();

  const handleEmailSubmit = useCallback(
    async (email: string) => {
      const encodedEmail = encodeURIComponent(email);
      const redirectToEmailSignUp = `${AppRoutes.SIGN_UP_WITH_EMAIL}?email=${encodedEmail}`;

      try {
        const { data } = await checkEmailInfo(email);

        sendGTMEvent(GTMEvent.EMAIL_SIGNUP_CLICKS);

        if (isGoogleEmailProvider(data?.provider)) {
          setGooglePromptEmail(email);
        } else {
          navigate(redirectToEmailSignUp);
        }
      } catch (error) {
        navigate(redirectToEmailSignUp);
      }
    },
    [checkEmailInfo, navigate],
  );

  if (googlePromptEmail) {
    return <GoogleSignupSuggestionSection email={googlePromptEmail} />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.signUpSectionContainer}>
          <div>
            <h2 className={styles.signUpHeader}>Sign up</h2>
            <div className={styles.signUpSubHeader}>
              Plans starting at $5/mo. Get started for free
            </div>
          </div>
          <div className={styles.ssoSection}>
            <SSOButton
              ssoProvider={SSOProvider.GOOGLE}
              setAuthLoading={setAuthLoading}
            />
            <SSOButton
              ssoProvider={SSOProvider.MICROSOFT}
              setAuthLoading={setAuthLoading}
            />
            <SSOButton
              ssoProvider={SSOProvider.APPLE}
              setAuthLoading={setAuthLoading}
            />
            <SSOButton
              ssoProvider={SSOProvider.AMAZON}
              setAuthLoading={setAuthLoading}
            />
            <SSOButton
              ssoProvider={SSOProvider.LINKEDIN}
              setAuthLoading={setAuthLoading}
            />
            <SSOButton
              ssoProvider={SSOProvider.FACEBOOK}
              setAuthLoading={setAuthLoading}
            />
          </div>
          <div className={styles.ssoSectionSeparator}>
            <div className={styles.lineSeparator} />
            <div>OR</div>
            <div className={styles.lineSeparator} />
          </div>
          <EmailSubmitForm onSubmit={handleEmailSubmit} />
          <div className={styles.alreadySignIn}>
            <span className={styles.alreadySignInText}>
              Already have an account?
            </span>
            &nbsp;
            <Link to={AppRoutes.LOGIN} className={styles.loginLink}>
              Log in
            </Link>
          </div>

          <TermsSection />
        </div>

        <div className={styles.leftRightSectionSeparator} />

        <SignupFeaturesSection />
      </div>
    </div>
  );
};
