import researcherIcon from 'src/images/icons/art-researcher.svg';
import researcherDarkIcon from 'src/images/icons/art-researcher-dark.svg';
import styles from './SuperAgentUpsell.module.scss';
import { useSession, useThemeActions, useColorTheme } from 'src/hooks';
import { SignupForFreeButton } from 'src/components/SignupForFreeButton/SignupForFreeButton';
import { UpgradeButton } from 'src/components/UpgradeButton';
import { Button } from 'src/v2/commonComponents/Button';
import { ChatModeSidePanel } from 'src/types';

interface SuperAgentUpsellProps {
  isVisible?: boolean;
}

export const SuperAgentUpsell = ({ isVisible }: SuperAgentUpsellProps) => {
  const {
    appUser: { settings },
    isOpenTier,
  } = useSession();

  const { toggleSettingsPanelExpanded, updateChatModeSidePanel } =
    useThemeActions();

  const { isDarkTheme } = useColorTheme();

  const handleClick = () => {
    toggleSettingsPanelExpanded(true);
    updateChatModeSidePanel(ChatModeSidePanel.NINJA_AGENTS);
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <img
          src={isDarkTheme ? researcherDarkIcon : researcherIcon}
          alt="SuperAgent"
        />

        <p className={styles.description}>
          An advanced research agent that dives deep into your questions,
          leveraging real-time web data and relevant sources to deliver more
          accurate and richer answers.
        </p>
      </div>

      {isOpenTier && (
        <SignupForFreeButton
          dataGTM="SuperAgent-message-signup"
          title="Sign Up"
        />
      )}

      {!isOpenTier && !settings?.research?.deep_research?.is_capable && (
        <UpgradeButton
          dataGTM="SuperAgent-message-upgrade"
          title="Upgrade to Pro"
        />
      )}

      {settings?.research?.deep_research?.is_capable &&
        !settings?.research?.deep_research?.enabled && (
          <Button onClick={handleClick}>Enable Super Agent</Button>
        )}
    </div>
  );
};
