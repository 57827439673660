import { FormInput } from 'src/components/FormInput';
import { FormProvider } from 'src/components/FormProvider';

import styles from './EmailSubmitForm.module.scss';
import { useForm } from 'react-hook-form';

type EmailSubmitFormProps = {
  onSubmit: (email: string) => Promise<void>;
};

enum SignupFields {
  EMAIL = 'email',
}

interface FormData {
  [SignupFields.EMAIL]: string;
}

export const EmailSubmitForm = ({ onSubmit }: EmailSubmitFormProps) => {
  const methods = useForm<FormData>({
    defaultValues: {
      [SignupFields.EMAIL]: '',
    },
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const submitForm = async (formData: FormData) => {
    await onSubmit(formData[SignupFields.EMAIL].toLowerCase());
  };

  return (
    <FormProvider<FormData> methods={methods}>
      <form onSubmit={handleSubmit(submitForm)} className={styles.root}>
        <FormInput
          name={SignupFields.EMAIL}
          type="email"
          className={styles.email}
          placeholder="Enter Email"
          required={true}
        />
        <button
          className={styles.submitButton}
          type="submit"
          disabled={isSubmitting}
        >
          <span>Sign up with Email</span>
        </button>
      </form>
    </FormProvider>
  );
};
