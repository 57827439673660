import { useContext, useRef, useState } from 'react';
import Webcam, { WebcamProps } from 'react-webcam';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Camera } from '@phosphor-icons/react';
import { isDesktop } from 'react-device-detect';
import { SVG_SIZE_M } from 'src/constants';
import { Modal } from 'src/components/Modal';
import {
  useVisible,
  useAttachFile,
  useOutsideClick,
  useSession,
  useBreakpoint,
} from 'src/hooks';
import styles from './CapturePhoto.module.scss';
import { convertBase64ToFile } from 'src/utils';
import { CaptureActions } from './components/CaptureActions';
import { ImageEffectTransition } from 'src/animations/ImageEffectTransition';
import { GuestAuthLinks } from 'src/pages/AuthPages/components/GuestAuthLinks';
import ThreadInputBoxContext, {
  ThreadInputBoxContextType,
} from 'src/contexts/ThreadInputBoxContext';
import { Button } from 'src/v2/commonComponents/Button';

const videoConstraints: WebcamProps['videoConstraints'] = {
  height: 480,
  width: 640,
};

export const CapturePhoto = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { isOpenTier } = useSession();

  const { isMobile } = useBreakpoint();

  const { handleUploadedFiles } = useAttachFile();

  const { promptLoading } = useContext<ThreadInputBoxContextType>(
    ThreadInputBoxContext,
  );

  const {
    isVisible: isModalVisible,
    handleVisibilityRemove: onModalClose,
    handleVisibilitySet: onModalOpen,
  } = useVisible();

  const {
    isVisible: isConfirmActionsVisible,
    handleVisibilityRemove: onConfirmActionsHide,
    handleVisibilitySet: onConfirmActionsShow,
  } = useVisible();

  const {
    isVisible: isCaptureVisible,
    handleVisibilityRemove: onCaptureHide,
    handleVisibilitySet: onCaptureShow,
  } = useVisible(true);

  const {
    isVisible: isVisibleTooltip,
    handleVisibilitySet: onShowTooltip,
    handleVisibilityRemove: onHideTooltip,
  } = useVisible();

  useOutsideClick(containerRef, onHideTooltip);

  const webcamRef = useRef<Webcam | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);

  const [imgSrc, setImgSrc] = useState<string | null>(null);

  const handleCaptureClick = () => {
    onCaptureHide();

    const screenshot = webcamRef.current?.getScreenshot();

    if (screenshot) {
      setImgSrc(screenshot);
    }
  };

  const handleModalClose = () => {
    onModalClose();
    webcamRef.current = null;
    setImgSrc(null);
    onCaptureShow();
    onConfirmActionsHide();
  };

  const handleRetakeClick = () => {
    setImgSrc(null);
    onConfirmActionsHide();
  };

  const handleUpload = async () => {
    if (!imgSrc) {
      return;
    }

    const file = convertBase64ToFile(imgSrc);

    await handleUploadedFiles([file]);
    handleModalClose();
  };

  const handleButtonClick = () => {
    if (isOpenTier) {
      onShowTooltip();
    } else {
      onModalOpen();
    }
  };

  if (!isDesktop) {
    return null;
  }

  return (
    <div ref={containerRef} className={styles.root}>
      <Button
        onClick={handleButtonClick}
        data-tooltip-id="capture-photo-tooltip"
        data-tooltip-place={isMobile ? 'bottom-start' : 'top-start'}
        disabled={promptLoading}
        shape="round"
        color="transparent"
      >
        <Camera size={SVG_SIZE_M} />
      </Button>

      {/* TODO(olha): create a common component to avoid code duplication */}
      <ReactTooltip
        clickable
        id="capture-photo-tooltip"
        disableStyleInjection
        noArrow={true}
        className="nj-attachment-tooltip"
        isOpen={isVisibleTooltip}
        offset={isMobile ? 0 : 20}
        imperativeModeOnly={true}
        openOnClick
      >
        <span>You’ll need a sign-in account to attach an image</span>
        <GuestAuthLinks dataGTM="Chat-thread-attach-file-signup" />
      </ReactTooltip>

      <Modal
        className={styles.modal}
        open={isModalVisible}
        onClose={handleModalClose}
      >
        <div className={styles.modalBody}>
          <p className={styles.title}>Take a photo</p>

          <div className={styles.mediaWrapper}>
            <ImageEffectTransition inProp={!!imgSrc} nodeRef={imageRef}>
              {imgSrc ? (
                <img ref={imageRef} src={imgSrc || ''} alt="user-photo" />
              ) : (
                <></>
              )}
            </ImageEffectTransition>

            {!imgSrc && isModalVisible && (
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
            )}
          </div>

          <CaptureActions
            isCaptureVisible={isCaptureVisible}
            isConfirmActionsVisible={isConfirmActionsVisible}
            onCaptureShow={onCaptureShow}
            onConfirmActionsShow={onConfirmActionsShow}
            onCaptureClick={handleCaptureClick}
            onRetakeClick={handleRetakeClick}
            onUploadClick={handleUpload}
          />
        </div>
      </Modal>
    </div>
  );
};
