import { createApi } from '@reduxjs/toolkit/query/react';
import { baseWalletQuery } from './config';
import {
  PaymentsSubscriptionInfo,
  SubscriptionIntent,
  CreateSubscriptionRequest,
  SubscriptionPlan,
  UpgradeDowngradeSubscriptionRequest,
  GetSubscriptionPlansRequest,
  CreatePaypalSubscriptionRequest,
} from 'src/types';
import { env } from 'src/env';

// import log from 'src/utils/logger';

export enum WalletTags {
  Token = 'Token',
  Costs = 'Costs',
  UserSubscriptionInfo = 'UserSubscriptionInfo',
  BudgetQuota = 'BudgetQuota',
  TasksQuota = 'TasksQuota',
  TasksCosts = 'TasksCosts',
  SessionVerification = 'SessionVerification',
  Plans = 'Plans',
}

export interface UpgradeSubscriptionArgs {
  user_id: string;
  lookup_key: string;
  promotion_code?: string;
  embedded?: boolean;
  add_on_amount_usd_cents?: 500 | 1000 | 1500;
}

export interface UpgradeSubscriptionResult {
  checkout_session?: {
    url: string;
    client_secret: string;
  };
  billing_portal_session?: {
    url: string;
  };
}

export const walletApi = createApi({
  reducerPath: 'walletApi',
  tagTypes: [
    WalletTags.Token,
    WalletTags.Costs,
    WalletTags.UserSubscriptionInfo,
    WalletTags.BudgetQuota,
    WalletTags.TasksQuota,
    WalletTags.TasksCosts,
    WalletTags.SessionVerification,
    WalletTags.Plans,
  ],
  refetchOnMountOrArgChange: true, // till not implemented PDU
  baseQuery: baseWalletQuery,
  endpoints: (builder) => ({
    // TODO(olha): add types from fast api when it will be ready
    getStripePrices: builder.query<unknown, { user_id: string }>({
      query: ({ user_id }) => `/stripe/prices/user/${user_id}`,
      providesTags: () => [WalletTags.Token],
      keepUnusedDataFor: 60 * 60 * 10,
    }),
    // TODO(olha): add types from fast api when it will be ready
    getSessionInfo: builder.query<
      unknown,
      { user_id: string; session_id: string }
    >({
      query: ({ user_id, session_id }) =>
        `/stripe/user/${user_id}/session-id/${session_id}`,
      providesTags: () => [WalletTags.SessionVerification],
    }),
    // TODO: add types from fast api when it is ready
    getStripeCosts: builder.query<
      { charged_tasks: never[]; next_evaluated_key: string },
      { user_id: string; next_evaluated_key: string }
    >({
      query: ({ user_id, next_evaluated_key }) =>
        `/stripe/activity/user/${user_id}${next_evaluated_key ? '?next_evaluated_key=' + next_evaluated_key : ''}`,
      providesTags: () => [WalletTags.Costs],
    }),
    // TODO: add types from fast api when it is ready
    getUserSubscriptionInfo: builder.query<
      PaymentsSubscriptionInfo,
      { user_id: string }
    >({
      query: ({ user_id }) => `/subscriptions/user/${user_id}`,
      providesTags: () => [WalletTags.UserSubscriptionInfo],
    }),
    // TODO: add types from fast api when it is ready
    getUserBudgetQuotaInfo: builder.query<
      {
        amount: number;
        is_low_balance: boolean;
        quota_exceeded: boolean;
      },
      { user_id: string }
    >({
      query: ({ user_id }) => `/stripe/budget-quota/user/${user_id}`,
      providesTags: () => [WalletTags.BudgetQuota],
    }),
    // TODO: add types from fast api when it is ready
    getUserTaskQuotaInfo: builder.query<
      {
        count: number;
      },
      { user_id: string }
    >({
      query: ({ user_id }) => `/stripe/task-count-quota/user/${user_id}`,
      providesTags: () => [WalletTags.TasksQuota],
      keepUnusedDataFor: 60 * 60 * 10,
    }),
    // TODO(olha): add types from fast api when it will be ready
    upgradeSubscription: builder.mutation<
      UpgradeSubscriptionResult,
      UpgradeSubscriptionArgs
    >({
      query: ({
        user_id,
        lookup_key,
        promotion_code,
        embedded,
        add_on_amount_usd_cents,
      }) => ({
        url: `stripe/checkout-session/user/${user_id}`,
        method: 'POST',
        params: {
          lookup_key,
          promotion_code,
          embedded,
          add_on_amount_usd_cents,
        },
        body: {
          success_url: env.REACT_APP_NINJA_UI_URL,
          cancel_url: env.REACT_APP_NINJA_UI_URL,
        },
      }),
    }),
    renewSubscription: builder.mutation({
      query: ({ user_id, lookup_key }) => ({
        url: `stripe/subscription/renew/early/user/${user_id}?lookup_key=${lookup_key}`,
        method: 'POST',
      }),
    }),
    // TODO(olha): add types from fast api when it will be ready
    getBillingPortalUrl: builder.query<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      any,
      { user_id: string; return_url?: string }
    >({
      query: ({ user_id, return_url }) => ({
        url: `/stripe/billing-portal/user/${user_id}`,
        params: {
          // TODO(olha): check why we have / in the UI_URL at the end
          return_url: `${env.REACT_APP_NINJA_UI_URL}${return_url}`,
        },
      }),
    }),
    getUserTaskCostsInfo: builder.query<
      {
        chargeable_costs: {
          item: {
            name: string;
          };
          cost: {
            amount: number;
            currency: string;
          };
        }[];
      },
      { user_id: string; task_id: string }
    >({
      query: ({ user_id, task_id }) =>
        `/stripe/costs/user/${user_id}/task/${task_id}`,
      providesTags: () => [WalletTags.TasksCosts],
    }),
    createSubscription: builder.mutation<
      SubscriptionIntent,
      CreateSubscriptionRequest
    >({
      query: ({ user_id, plan_id }) => ({
        url: `stripe/subscription/user/${user_id}?plan_id=${plan_id}`,
        method: 'PUT',
      }),
    }),
    updateDowngradeSubscription: builder.mutation<
      {
        status: string;
        approve_url?: string;
      },
      UpgradeDowngradeSubscriptionRequest
    >({
      query: ({ user_id, product_key, price_timestamp }) => ({
        url: `subscriptions/user/${user_id}/upgrade_or_downgrade?upgrade_product_key=${product_key}&price_timestamp=${price_timestamp}`,
        method: 'PUT',
      }),
    }),
    changePlan: builder.mutation<
      {
        status: string;
        approve_url?: string;
      },
      UpgradeDowngradeSubscriptionRequest
    >({
      query: ({ user_id, product_key, price_timestamp }) => ({
        url: `subscriptions/user/${user_id}/change_plan?product_key=${product_key}&price_timestamp=${price_timestamp}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, arg) => {
        if (!!result && !result.approve_url) {
          return [WalletTags.UserSubscriptionInfo];
        }
        return [];
      },
    }),
    getSubscriptionPlans: builder.query<
      SubscriptionPlan[],
      GetSubscriptionPlansRequest
    >({
      query: ({ user_id }) => `/plans/?user_id=${user_id}`,
      providesTags: () => [WalletTags.Plans],
      keepUnusedDataFor: 60 * 60 * 10,
    }),
    createPaypalSubscription: builder.mutation<
      string,
      CreatePaypalSubscriptionRequest
    >({
      query: ({ user_id, subscription_id }) => ({
        url: `paypal/subscription/${subscription_id}/user/${user_id}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, arg) => [
        WalletTags.UserSubscriptionInfo,
      ],
    }),
    cancelSubscription: builder.mutation<string, string>({
      query: (user_id) => ({
        url: `/subscriptions/user/${user_id}/cancel`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, arg) => [
        WalletTags.UserSubscriptionInfo,
      ],
    }),
    resumeSubscription: builder.mutation<string, string>({
      query: (user_id) => ({
        url: `/subscriptions/user/${user_id}/resume`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, arg) => [
        WalletTags.UserSubscriptionInfo,
      ],
    }),
  }),
});

export const {
  useGetUserBudgetQuotaInfoQuery,
  useGetUserTaskQuotaInfoQuery,
  useLazyGetUserTaskQuotaInfoQuery,
  useGetUserSubscriptionInfoQuery,
  useGetStripeCostsQuery,
  useGetStripePricesQuery,
  useUpgradeSubscriptionMutation,
  useLazyGetBillingPortalUrlQuery,
  useRenewSubscriptionMutation,
  useLazyGetUserBudgetQuotaInfoQuery,
  useGetUserTaskCostsInfoQuery,
  useLazyGetSessionInfoQuery,
  useCreateSubscriptionMutation,
  useGetSubscriptionPlansQuery,
  useUpdateDowngradeSubscriptionMutation,
  useCreatePaypalSubscriptionMutation,
  useCancelSubscriptionMutation,
  useResumeSubscriptionMutation,
  useChangePlanMutation,
} = walletApi;
