import { useExternalModels } from 'src/hooks';
import { Icon } from 'src/components/Icon';
import {
  SVG_SIZE_M,
  availableForProLLMs,
  externalModelsSet,
} from 'src/constants';
import { ExternalModelGroup } from '../ExternalModelGroup';
import { ExternalModelItemProps } from '../ExternalModelItem';
import { ExternalModelSettingsOption, NinjaSubscriptionPlan } from 'src/types';
import { AvailableDivider } from '../AvailableDivider';

const ICON_PROPS = {
  size: SVG_SIZE_M,
};

interface TextModelListProps {
  isLimitedForPro: boolean;
  isLimitedForUltra: boolean;
  selectedTextModels: string[];
  onClick: (id: string) => void;
}

export const TextModelList = ({
  isLimitedForPro,
  isLimitedForUltra,
  selectedTextModels,
  onClick,
}: TextModelListProps) => {
  const {
    textExternalModels: { amazon_bedrock, openai, google },
  } = useExternalModels();

  const getAvailability = (model: string, isCapable?: boolean) => {
    if ((!isLimitedForPro && !isLimitedForUltra) || isCapable) {
      return;
    }

    if (isLimitedForPro && availableForProLLMs.includes(model)) {
      return NinjaSubscriptionPlan.PRO;
    }

    return NinjaSubscriptionPlan.ULTRA;
  };

  const ninjaModels: ExternalModelItemProps[] = [
    {
      // (olha): this id is never used
      id: 'alias/ninjatech/agentic_llama',
      icon: <Icon type="ninjaOutlined" {...ICON_PROPS} />,
      title: 'Agentic Llama 3.1 405B',
      included: true,
    },
  ];

  const getModels = (
    modelGroup?: ExternalModelSettingsOption[],
  ): ExternalModelItemProps[] =>
    modelGroup?.map(({ display_name, model_name, is_capable }) => {
      const icon = externalModelsSet[model_name]?.icon_name;

      return {
        id: model_name,
        icon: icon ? <Icon type={icon} {...ICON_PROPS} /> : <></>,
        title: display_name,
        selected: selectedTextModels.includes(model_name),
        availableIn: getAvailability(model_name, is_capable),
        onClick: () => onClick(model_name),
      };
    }) || [];

  const availableAmazonModels = getModels(amazon_bedrock).filter(
    (item) => !item.availableIn,
  );

  const disabledAmazonModels = getModels(amazon_bedrock).filter(
    (item) => !!item.availableIn,
  );

  const availableOpenAIModels = getModels(openai).filter(
    (item) => !item.availableIn,
  );

  const disabledOpenAIModels = getModels(openai).filter(
    (item) => !!item.availableIn,
  );

  const availableGoogleModels = getModels(google).filter(
    (item) => !item.availableIn,
  );

  const disabledGoogleModels = getModels(google).filter(
    (item) => !!item.availableIn,
  );

  return (
    <>
      <ExternalModelGroup groupTitle="Ninja Agent" modelItems={ninjaModels} />

      <ExternalModelGroup
        groupTitle="Amazon Bedrock"
        modelItems={availableAmazonModels}
      />

      <ExternalModelGroup
        groupTitle="OpenAI"
        modelItems={availableOpenAIModels}
      />

      <ExternalModelGroup
        groupTitle="Google"
        modelItems={availableGoogleModels}
      />

      {(isLimitedForPro || isLimitedForUltra) && (
        <AvailableDivider withPro={isLimitedForUltra} />
      )}

      <ExternalModelGroup
        groupTitle="Amazon Bedrock"
        modelItems={disabledAmazonModels}
      />

      <ExternalModelGroup
        groupTitle="OpenAI"
        modelItems={disabledOpenAIModels}
      />

      <ExternalModelGroup
        groupTitle="Google"
        modelItems={disabledGoogleModels}
      />
    </>
  );
};
