import { useSession, useSubscriptionInfo, useVisible } from 'src/hooks';
import { ManageAccountContentPage } from '../ManageAccountContentPage/ManageAccountContentPage';
import { SubscriptionPlansForm } from '../SubscriptionPlansForm';
import { CancelSubscription } from '../CancelSubscription';
import './SubscriptionPlans.scss';
import { useGetSubscriptionPlansQuery } from 'src/store/services';
import { PaymentPlanInterval } from 'src/types';
import { UpdateMonthlyToAnnuallySection } from 'src/v2/components/UpdateMonthlyToAnnuallySection';
import { LabelEnterprise } from 'src/components/LabelEnterprise';

export const SubscriptionPlans = () => {
  const { isSubscribedTier, appUser, isEnterpriseTier } = useSession();
  const {
    isVisible: IsCheckoutOpen,
    handleVisibilitySet: onOpenCheckout,
    handleVisibilityRemove: onCloseCheckout,
  } = useVisible(false);

  const { data: plans } = useGetSubscriptionPlansQuery(
    { user_id: appUser.user_id },
    { skip: !isSubscribedTier || isEnterpriseTier },
  );

  const { subscriptionInfo } = useSubscriptionInfo();

  const annuallyPlanInfo = plans
    ? plans.filter(
        (item) =>
          item.plan_tier === (appUser.tier_id as string) &&
          item.period === PaymentPlanInterval.ANNUALLY,
      )[0]
    : undefined;

  return !isEnterpriseTier ? (
    <>
      <ManageAccountContentPage
        title="Subscription"
        errorMessage={
          subscriptionInfo?.processing_plan_change
            ? 'Payment processing in progress'
            : undefined
        }
      >
        <div className="nj-subscription-plans">
          <SubscriptionPlansForm onOpenCheckout={onOpenCheckout} />

          {isSubscribedTier && (
            <>
              <hr className="divider" />

              <CancelSubscription />
            </>
          )}
        </div>
      </ManageAccountContentPage>
      <UpdateMonthlyToAnnuallySection
        isOpen={IsCheckoutOpen}
        onClose={onCloseCheckout}
        selectedPlan={annuallyPlanInfo}
      />
    </>
  ) : (
    <ManageAccountContentPage title="Subscription">
      <div className="enterprise-root">
        <h4 className="subtitle">Your subscription</h4>
        <div className="plan">
          <p>Plan</p>
          <p className="nj-subscription-plans-form--value">
            <LabelEnterprise />
          </p>
        </div>
      </div>
    </ManageAccountContentPage>
  );
};
