import { useRef } from 'react';
import { useThreads } from 'src/hooks';
import { Message } from 'src/types';
import { HeadCircuit } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import classNames from 'classnames';
import { useCollapse } from 'react-collapsed';

type GetToggleProps = ReturnType<typeof useCollapse>['getToggleProps'];

interface RedoSkillActionsProps {
  message: Message;
  isShowRedoSkillActions?: boolean;
  onToggleRedoSkillActions: GetToggleProps;
  onCloseRedoWithPersona: () => void;
  onCloseNegativeFeedbackForm: () => void;
}

export const RedoSkillActions = ({
  message,
  isShowRedoSkillActions = false,
  onToggleRedoSkillActions,
  onCloseRedoWithPersona,
  onCloseNegativeFeedbackForm,
}: RedoSkillActionsProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { isSubmitHappened } = useThreads();
  const userInput = message?.refined_query || message?.clean_query || '';
  const messageIdValidPart = message.message_id?.slice(-6);

  function toggleActions() {
    onCloseRedoWithPersona();
    onCloseNegativeFeedbackForm();
  }

  return (
    <div {...onToggleRedoSkillActions()}>
      <div
        className={classNames('nj-message-footer--redo-skill-actions', {
          selected: isShowRedoSkillActions,
        })}
        ref={containerRef}
      >
        <button
          onClick={toggleActions}
          id={`redo-with-skills_${messageIdValidPart}`}
          disabled={isSubmitHappened || !userInput}
          title="Regenerate with different Agent"
        >
          <HeadCircuit
            size={SVG_SIZE_M}
            weight={isShowRedoSkillActions ? 'fill' : 'regular'}
            color="currentColor"
          />
          Try different agent
        </button>
      </div>
    </div>
  );
};
