import styles from './SigninPage.module.scss';
import { Header } from 'src/v2/pages/auth/components/Header';
import { MeetNinjaSection } from 'src/v2/pages/auth/components/MeetNinjaSection/MeetNinjaSection';
import { Footer } from 'src/v2/pages/auth/components/Footer';
import { MainSigninSection } from 'src/v2/pages/auth/components/MainSigninSection';
import { useRandomSignupBackgroundStyle } from 'src/hooks/useRandomSignupBackgroundStyle';
import { isEnterprise } from 'src/utils';
import { EnterpriseMainSigninSection } from '../components/enterprise/EnterpriseMainSigninSection';
import { EnterpriseMeetNinjaSection } from 'src/v2/pages/auth/components/MeetNinjaSection';
import { useQueryParams } from 'src/hooks';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export const SigninPage = () => {
  const { backgroundImageStyle } = useRandomSignupBackgroundStyle();

  const {
    searchParams: { email_verified },
    removeSearchParam,
  } = useQueryParams();

  useEffect(() => {
    if (email_verified) {
      toast.success('Email verification successful');
      removeSearchParam('email_verified');
    }
  }, [email_verified, removeSearchParam]);

  return (
    <div className={styles.root} style={backgroundImageStyle}>
      <Header addSEOTags />
      <main className={styles.container}>
        {isEnterprise ? (
          <>
            <EnterpriseMeetNinjaSection />
            <EnterpriseMainSigninSection />
          </>
        ) : (
          <>
            <MeetNinjaSection isSignup={false} />
            <MainSigninSection />
          </>
        )}
      </main>
      {!isEnterprise && <Footer />}
    </div>
  );
};
