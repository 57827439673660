import { LEARN_MORE_PRICES_LINK } from 'src/constants/externalLinks';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/types';
import { useSubscriptionInfo } from 'src/hooks';
import { toast } from 'react-toastify';
import { SUBSCRIPTION_PENDING_CHANGES_NOTIFICATION_MESSAGE } from 'src/constants';

interface UpgradeLearnMoreButtonsProps {
  dataGTM: string;
}

export const UpgradeLearnMoreButtons = ({
  dataGTM,
}: UpgradeLearnMoreButtonsProps) => {
  const navigate = useNavigate();
  const { subscriptionInfo } = useSubscriptionInfo();

  const handleUpgradeClick = () => {
    if (
      !!subscriptionInfo?.is_cancelled ||
      !!subscriptionInfo?.has_scheduled_downgrade ||
      !!subscriptionInfo?.has_scheduled_plan_change ||
      !!subscriptionInfo?.processing_plan_change
    ) {
      toast.error(SUBSCRIPTION_PENDING_CHANGES_NOTIFICATION_MESSAGE);
    } else {
      navigate(AppRoutes.PAYMENT);
    }
  };

  return (
    <div className="nj-tier-auth-footer-button-wrapper">
      <a
        target="_blank"
        rel="noreferrer"
        href={LEARN_MORE_PRICES_LINK}
        className="nj-tier-auth-footer-button learn-more-button"
      >
        Learn more
      </a>
      <button
        id={dataGTM}
        type="button"
        className="nj-tier-auth-footer-button signup-button"
        onClick={handleUpgradeClick}
      >
        Upgrade
      </button>
    </div>
  );
};
