import { useEffect, useMemo, useState } from 'react';
import {
  type CommunicationToneID,
  ConversationRole,
  isChitChatCard,
  isImageGenerationCard,
  Message,
  MessageSkill,
  MessageType,
  TaskSkill,
  TaskState,
} from 'src/types';
import {
  useAvatarPreferences,
  useCurrentConversationTasks,
  useSession,
  useSubmitUserInput,
} from 'src/hooks';
import {
  getSkillFromMessage,
  getTaskIdFromMessage,
  uppercaseFirstLetter,
} from 'src/utils';
import { RegenerateMessageButton } from '../RegenerateMessageButton';
import { CopyMessageButton } from 'src/pages/ManageTasksChatPage/components/ChatMessage/components/CopyMessageButton';
import { ShareButton } from 'src/components/ShareButton';
import { NegativeFeedbackAdditionalForm } from 'src/components/FlatAppearance/components/NegativeFeedbackAdditionalForm';
import { MessageModal } from '../MessageModal';
import {
  CommunicationStyle,
  RedoSkillButtons,
  SVG_SIZE_M,
} from 'src/constants';
import styles from './MessageFooter.module.scss';
import { CollapsedItem } from 'src/components/CollapsedItem';
import { ConversationalStyleSection } from 'src/pages/ManageTasksChatPage/components/AvatarSelectorContent/components/ConversationalStyleSection';
import { ConversationalToneSection } from 'src/pages/ManageTasksChatPage/components/AvatarSelectorContent/components/ConversationalToneSection';
import { Persona } from 'src/types/models/Persona';
import { PersonaSectionItemKey } from 'src/pages/ManageTasksChatPage/components/AvatarSelectorContent';
import { useCollapse } from 'react-collapsed';
import { FooterMenu } from './components/FooterMenu';

interface MessageFooterPops {
  message: Message;
}

export const MessageFooter = ({ message }: MessageFooterPops) => {
  const { appUser, isOpenTier, isFreeTier, isStandardTier } = useSession();
  const {
    role,
    conversation_id,
    message_id,
    message_type,
    is_final_answer,
    feedback,
  } = message;

  const { selectedCommunicationTone, communicationTonesOptions } =
    useAvatarPreferences();

  // TODO: break this component into smaller ones splitting them per Action
  const {
    getToggleProps: onToggleNegativeFeedbackForm,
    isExpanded: isShowNegativeFeedbackForm,
    setExpanded: setExpandedNegativeFeedbackForm,
    getCollapseProps: getCollapsePropsNegativeFeedbackForm,
  } = useCollapse({
    defaultExpanded: false,
  });

  const {
    getToggleProps: onToggleRedoSkillActions,
    isExpanded: isShowRedoSkillActions,
    setExpanded: setExpandedRedoSkillActions,
    getCollapseProps: getCollapsePropsRedoSkillActions,
  } = useCollapse({
    defaultExpanded: false,
  });

  const {
    getToggleProps: onToggleRedoWithPersona,
    isExpanded: isShowRedoWithPersona,
    setExpanded: setExpandedRedoWithPersona,
    getCollapseProps: getCollapsePropsRedoWithPersona,
  } = useCollapse({
    defaultExpanded: false,
  });

  const [currentConversationalStyle, setCurrentConversationalStyle] = useState<
    number | number[]
  >(3);
  const [currentCommunicationTone, setCurrentCommunicationTone] = useState(
    selectedCommunicationTone,
  );

  const userInput = message?.refined_query || message?.clean_query || '';

  const { getTaskFromConversationById } = useCurrentConversationTasks();

  useEffect(() => {
    if (!!appUser.persona?.conversational_style) {
      setCurrentConversationalStyle(appUser.persona?.conversational_style);
    }
  }, [appUser.persona?.conversational_style]);

  useEffect(() => {
    if (!!selectedCommunicationTone) {
      setCurrentCommunicationTone(selectedCommunicationTone);
    }
  }, [selectedCommunicationTone]);

  const taskId = getTaskIdFromMessage(message);
  const taskState = taskId ? getTaskFromConversationById(taskId)?.state : null;
  const { onSubmitUserInput } = useSubmitUserInput();

  const negativeFeedbackObject = {
    feedback_id: feedback?.feedback_id || '',
    user_id: appUser.user_id,
    conversation_id: conversation_id || '',
    message_id: message_id || '',
    feedback_type: feedback?.feedback_type,
    task_id: taskId,
  };

  const currentSkill = useMemo(() => {
    return message.message_type
      ? getSkillFromMessage(
          message.message_type,
          isChitChatCard(message.payload)
            ? message.payload.data?.skill
            : undefined,
        )
      : '';
  }, [message.message_type, message.payload]);

  const skillsForRedo = useMemo(() => {
    return RedoSkillButtons.filter(({ skill }) => skill !== currentSkill);
  }, [currentSkill]);

  const handleChangeCommunicationTone = (value: string) => {
    const updatedValue = communicationTonesOptions.find(
      (item) => item.id === value,
    );
    setCurrentCommunicationTone(updatedValue);
  };

  const getAccordionItems = useMemo(() => {
    return [
      {
        key: PersonaSectionItemKey.style,
        title: 'Conversational style',
        valueSubtitle: CommunicationStyle[currentConversationalStyle as number],
        content: (
          <ConversationalStyleSection
            onChange={setCurrentConversationalStyle}
          />
        ),
      },
      {
        key: PersonaSectionItemKey.tone,
        title: 'Conversational tone',
        valueSubtitle: currentCommunicationTone?.title || '',
        content: (
          <ConversationalToneSection
            value={currentCommunicationTone}
            onChange={handleChangeCommunicationTone}
            isShowUpsellMessage={true}
          />
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConversationalStyle, currentCommunicationTone]);

  const shouldShowSharingButtons = useMemo(() => {
    if (
      (isOpenTier || isFreeTier || isStandardTier) &&
      isChitChatCard(message.payload) &&
      message.payload.data?.skill === TaskSkill.DOCUMENT_QA
    ) {
      return false;
    }
    if ((isOpenTier || isFreeTier) && isImageGenerationCard(message.payload)) {
      return false;
    }
    if (message_type === MessageType.CONVERSATION && is_final_answer) {
      return true;
    }

    if (taskState === TaskState.DONE && is_final_answer) {
      return true;
    }

    if (!taskId && is_final_answer) {
      return true;
    }

    return false;
  }, [
    message_type,
    is_final_answer,
    taskState,
    taskId,
    isOpenTier,
    isFreeTier,
    isStandardTier,
    message,
  ]);

  if (role === ConversationRole.USER || !message_id) {
    return null;
  }

  const handleClick = (skill: MessageSkill) => {
    const preparedUserInput = `/${skill} ${userInput}`;
    onSubmitUserInput(preparedUserInput);

    setExpandedRedoSkillActions(false);
  };

  const handleRegenerateClick = () => {
    const persona = {
      ...appUser?.persona,
      communication_tone_id:
        currentCommunicationTone?.id as CommunicationToneID,
      conversational_style: currentConversationalStyle,
    };
    onSubmitUserInput(userInput, { persona: persona as Persona });

    setExpandedRedoWithPersona(false);
  };

  const hideAllOptions = () => {
    setExpandedRedoWithPersona(false);
    setExpandedRedoSkillActions(false);
    setExpandedNegativeFeedbackForm(false);
  };

  const handleCloseRedoWithPersona = () => {
    setExpandedRedoWithPersona(false);
  };

  const handleCloseNegativeFeedbackForm = () => {
    setExpandedNegativeFeedbackForm(false);
  };

  return (
    <div>
      <div className="nj-message-footer">
        <div className="nj-message-footer--wrapper">
          <RegenerateMessageButton
            message={message}
            hideAllOptions={hideAllOptions}
          />

          <CopyMessageButton
            item={message}
            withTitle
            disabled={
              !shouldShowSharingButtons ||
              message_type === MessageType.IMAGE_CARD ||
              message_type === MessageType.ERROR_MESSAGE
            }
          />

          <FooterMenu
            message={message}
            taskId={taskId}
            messageId={message_id}
            conversationId={conversation_id}
            messageType={message_type}
            feedback={feedback}
            onToggleNegativeFeedbackForm={onToggleNegativeFeedbackForm}
            isShowNegativeFeedbackForm={isShowNegativeFeedbackForm}
            onCloseRedoSkillActions={() => {
              setExpandedRedoSkillActions(false);
            }}
            onCloseRedoWithPersona={handleCloseRedoWithPersona}
            onCloseNegativeFeedbackForm={handleCloseNegativeFeedbackForm}
            disabled={message_type === MessageType.ERROR_MESSAGE}
            isShowRedoWithPersona={isShowRedoWithPersona}
            onToggleRedoWithPersona={onToggleRedoWithPersona}
            isShowRedoSkillActions={isShowRedoSkillActions}
            onToggleRedoSkillActions={onToggleRedoSkillActions}
          />
        </div>

        <div className="nj-message-footer--wrapper">
          {message.message_type !==
            MessageType.SCHEDULER_TASK_CREATION_CARD && (
            <ShareButton
              withTitle
              messageId={message.message_id || ''}
              buttonIconSize={SVG_SIZE_M}
              disabled={
                !shouldShowSharingButtons ||
                message_type === MessageType.ERROR_MESSAGE
              }
            />
          )}
        </div>
      </div>

      <div
        className={styles.medium}
        {...getCollapsePropsNegativeFeedbackForm()}
      >
        <MessageModal
          title="Tell us more about this rating"
          onClick={handleCloseNegativeFeedbackForm}
        >
          <NegativeFeedbackAdditionalForm
            onClose={handleCloseNegativeFeedbackForm}
            messageType={message_type || MessageType.CONVERSATION}
            feedback={negativeFeedbackObject}
          />
        </MessageModal>
      </div>

      <div className={styles.small} {...getCollapsePropsRedoSkillActions()}>
        <MessageModal
          title="Regenerate with different Agent"
          onClick={() => {
            setExpandedRedoSkillActions(false);
          }}
        >
          <div className={styles.agents}>
            {skillsForRedo.map(({ skill, icon: SkillIcon }) => {
              if (
                (skill === MessageSkill.CODER &&
                  currentSkill === MessageSkill.WRITER) ||
                (skill === MessageSkill.WRITER &&
                  currentSkill === MessageSkill.CODER)
              ) {
                return null;
              }
              return (
                <button
                  className={styles.row}
                  onClick={() => handleClick(skill)}
                  key={skill}
                  // todo enabled for now
                  // disabled={
                  //   skill === MessageSkill.SCHEDULER &&
                  //   !appUser?.settings?.features?.redo_as_scheduler?.is_capable
                  // }
                >
                  <SkillIcon size={SVG_SIZE_M} />
                  <span>{uppercaseFirstLetter(skill)}</span>
                </button>
              );
            })}
          </div>
        </MessageModal>
      </div>

      <div className={styles.large} {...getCollapsePropsRedoWithPersona()}>
        <MessageModal
          title="Regenerate with different tone & verbosity"
          onClick={handleCloseRedoWithPersona}
        >
          <>
            <div className={styles.accordion}>
              {getAccordionItems.map(
                ({ key, title, content, valueSubtitle }) => (
                  <CollapsedItem
                    title={title}
                    content={content}
                    valueSubtitle={valueSubtitle}
                    subtitle={valueSubtitle}
                    key={key}
                  />
                ),
              )}
            </div>
            <button
              className={styles.regenerate}
              onClick={handleRegenerateClick}
            >
              Regenerate
            </button>
          </>
        </MessageModal>
      </div>
    </div>
  );
};
