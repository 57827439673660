import styles from './SignupFeaturesSection.module.scss';
import { ChatGPTLogo } from 'src/images/logos/chat-gpt';
import { AnthropicLogo } from 'src/images/logos/anthropic-logo';
import { Icon } from 'src/components/Icon';
import { CheckMarkIcon } from 'src/v2/pages/auth/components/SignupFeaturesSection/CheckMarkIcon';
import { MetaLogo } from 'src/images/logos/meta-logo';

export const FEATURES_LIST = [
  {
    title: 'Research & Writing',
    icon: <CheckMarkIcon width={20} height={20} />,
  },
  {
    title: 'Code Generation & Debugging',
    icon: <CheckMarkIcon width={20} height={20} />,
  },
  {
    title: 'Image Generation & Editing',
    icon: <CheckMarkIcon width={20} height={20} />,
  },
  {
    title: 'File Analysis',
    icon: <CheckMarkIcon width={20} height={20} />,
  },
  {
    title: 'Meeting Scheduling',
    icon: <CheckMarkIcon width={20} height={20} />,
  },
  {
    title: 'Access to the best AI models in one subscription',
    icon: <CheckMarkIcon width={20} height={20} />,
  },
];

export const MODELS_LIST = [
  {
    title: 'Open AI',
    icon: <ChatGPTLogo color={'black'} />,
  },
  {
    title: 'Anthropic',
    icon: <AnthropicLogo color={'#181818'} />,
  },
  {
    title: 'Google',
    icon: <Icon type="googleGeminiProLogo" size={20} />,
  },
  {
    title: 'Meta',
    icon: <MetaLogo />,
  },
];

export const SignupFeaturesSection = () => {
  return (
    <div className={styles.root}>
      <div className={styles.mainTitle}>
        Unlock <span className={styles.highlighted}>Unlimited</span> AI tools
        for all your tasks
      </div>
      <div className={styles.featuresSection}>
        <ul className={styles.featuresList}>
          {FEATURES_LIST.map((item, index) => (
            <li className={styles.featureRow} key={index}>
              <span className={styles.checkMark}>{item.icon}</span>
              <span>{item.title}</span>
            </li>
          ))}
        </ul>
        <ul className={styles.modelsList}>
          {MODELS_LIST.map((item, index) => (
            <li className={styles.featureRow} key={index}>
              <span className={styles.modelIcon}>{item.icon}</span>
              <span>{item.title}</span>
            </li>
          ))}
          <li className={styles.featureLastRow}>20+ models</li>
        </ul>
      </div>
    </div>
  );
};
