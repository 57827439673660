import styles from './MainSigninSection.module.scss';
import { SSOButton } from 'src/v2/pages/auth/components/SSOButton/SSOButton';
import { AppRoutes, SSOProvider } from 'src/types';
import { useContext } from 'react';
import AuthContext from 'src/contexts/AuthContext';
import { Link } from 'react-router-dom';
import { TermsSection } from 'src/v2/pages/auth/components/TermsSection/TermsSection';

export const MainSigninSection = () => {
  const { setAuthLoading } = useContext(AuthContext);

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div>
          <h2 className={styles.signInHeader}>Welcome back!</h2>
          <div className={styles.signInSubHeader}>Login to your account</div>
        </div>
        <div className={styles.ssoSection}>
          <SSOButton
            ssoProvider={SSOProvider.GOOGLE}
            setAuthLoading={setAuthLoading}
          />
          <SSOButton
            ssoProvider={SSOProvider.MICROSOFT}
            setAuthLoading={setAuthLoading}
          />
          <SSOButton
            ssoProvider={SSOProvider.APPLE}
            setAuthLoading={setAuthLoading}
          />
          <SSOButton
            ssoProvider={SSOProvider.AMAZON}
            setAuthLoading={setAuthLoading}
          />
          <SSOButton
            ssoProvider={SSOProvider.LINKEDIN}
            setAuthLoading={setAuthLoading}
          />
          <SSOButton
            ssoProvider={SSOProvider.FACEBOOK}
            setAuthLoading={setAuthLoading}
          />
        </div>
        <div className={styles.ssoSectionSeparator}>
          <div className={styles.lineSeparator} />
          <div>OR</div>
          <div className={styles.lineSeparator} />
        </div>
        <Link to={AppRoutes.LOGIN_WITH_EMAIL} className={styles.submitButton}>
          Login with Email
        </Link>
        <div className={styles.notMember}>
          <span className={styles.notMemberText}>Don’t have an account?</span>
          &nbsp;
          <Link to={AppRoutes.SIGN_UP} className={styles.loginLink}>
            Sign Up
          </Link>
        </div>

        <TermsSection />
      </div>
    </div>
  );
};
