import { useThreads } from 'src/hooks';
import { ConversationRole, Message } from 'src/types';

import { AvatarImage } from 'src/components/AvatarImage';
import { UserImage } from 'src/components/UserImage';
import { SpinnerSimple } from 'src/v2/commonComponents/SpinnerSimple';
import { SVG_SIZE_XL } from 'src/constants';
import styles from './MessageAuthorPicture.module.scss';

interface MessageAuthorPictureProps {
  message: Message;
  canShowProgress?: boolean;
}

export const MessageAuthorPicture = ({
  message,
  canShowProgress,
}: MessageAuthorPictureProps) => {
  const { isSubmitHappened } = useThreads();

  const { role, persona } = message;

  const isUser = role === ConversationRole.USER;

  return (
    <div className={styles.root}>
      {isUser ? (
        <div className={styles.icon}>
          <UserImage size={SVG_SIZE_XL} />
        </div>
      ) : (
        <AvatarImage persona={persona} size={SVG_SIZE_XL} />
      )}

      {isSubmitHappened &&
        role !== ConversationRole.USER &&
        canShowProgress && <SpinnerSimple className={styles.spinner} />}
    </div>
  );
};
