import styles from './SignupPage.module.scss';
import { Header } from 'src/v2/pages/auth/components/Header';
import { MeetNinjaSection } from 'src/v2/pages/auth/components/MeetNinjaSection/MeetNinjaSection';
import { MainSignupSection } from 'src/v2/pages/auth/components/MainSignupSection/MainSignupSection';
import { Footer } from 'src/v2/pages/auth/components/Footer';
import { useRandomSignupBackgroundStyle } from 'src/hooks/useRandomSignupBackgroundStyle';
import { isEnterprise } from 'src/utils';
import { EnterpriseMainSignupSection } from 'src/v2/pages/auth/components/enterprise/EnterpriseMainSignupSection';
import { EnterpriseMeetNinjaSection } from 'src/v2/pages/auth/components/MeetNinjaSection';

export const SignupPage = () => {
  const { backgroundImageStyle } = useRandomSignupBackgroundStyle();

  return (
    <div className={styles.root} style={backgroundImageStyle}>
      <Header isSignUp addSEOTags />
      <main className={styles.container}>
        {isEnterprise ? (
          <>
            <EnterpriseMeetNinjaSection />
            <EnterpriseMainSignupSection />
          </>
        ) : (
          <>
            <MeetNinjaSection isSignup />
            <MainSignupSection />
          </>
        )}
      </main>
      {!isEnterprise && <Footer />}
    </div>
  );
};
