import { createApi } from '@reduxjs/toolkit/query/react';
import { baseNondebugFeedbackQuery } from './config';
import { CreateFeedbackRequest } from 'src/types/models/CreateFeedbackRequest';
import { PatchFeedbackRequest } from 'src/types/models/PatchFeedbackRequest';
import { FeedbackResponse } from 'src/types/models/FeedbackResponse';
import { DeleteFeedbackRequest } from 'src/types/models/DeleteFeedbackRequest';

export const nondebugFeedbackApi = createApi({
  reducerPath: 'nondebugFeedbackApi',
  baseQuery: baseNondebugFeedbackQuery,
  endpoints: (build) => ({
    createFeedback: build.mutation<FeedbackResponse, CreateFeedbackRequest>({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
    }),
    updateFeedback: build.mutation<void, PatchFeedbackRequest>({
      query: (body) => ({
        url: `/${body.feedback_id}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteFeedback: build.mutation<void, DeleteFeedbackRequest>({
      query: (body) => ({
        url: `/${body.feedback_id}`,
        method: 'DELETE',
        body,
      }),
    }),
  }),
});

export const {
  useCreateFeedbackMutation,
  useUpdateFeedbackMutation,
  useDeleteFeedbackMutation,
} = nondebugFeedbackApi;
