import { useMemo, useRef } from 'react';
import { CaretUpDown } from '@phosphor-icons/react';
import { BottomDrawer } from 'src/components/BottomDrawer';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Icon } from 'src/components/Icon';
import { SVG_SIZE_M } from 'src/constants';
import { Button } from 'src/v2/commonComponents/Button';
import {
  useBreakpoint,
  useExternalModels,
  useOutsideClick,
  useVisible,
  useConversationParams,
  useUserMetadata,
} from 'src/hooks';
import { ExternalModelContainer } from './components/ExternalModelContainer';
import styles from './ExternalModelSelection.module.scss';
import { Counter } from 'src/v2/commonComponents/Counter';
import { ExternalModelSettingsOption } from 'src/types';
import { ExternalModelHint } from './components/ExternalModelHint';

const HINT_TOOLTIP_ANCHOR_ID = 'external-model-selection';
const TOOLTIP_ANCHOR_ID = 'external-model-selection-trigger';

export const ExternalModelSelection = () => {
  const { isLandingPage } = useConversationParams();

  const { textExternalModels, imageExternalModels } = useExternalModels();

  const containerRef = useRef(null);

  const { isMobileOrTablet } = useBreakpoint();
  const { isVisible, handleVisibilityRemove, handleVisibilityToggle } =
    useVisible();

  const { updateMetadata } = useUserMetadata();

  const {
    isVisible: isHintOpen,
    handleVisibilityRemove: onHintClose,
    handleVisibilitySet: onHintOpen,
  } = useVisible();

  const handleHintClose = () => {
    if (isHintOpen) {
      updateMetadata({
        external_model_onboarded: true,
      });
      onHintClose();
    }
  };

  const handleOutsideClick = () => {
    if (isMobileOrTablet) {
      return;
    }

    handleVisibilityRemove();
  };

  useOutsideClick(containerRef, handleOutsideClick);

  const handleToggleClick = () => {
    handleHintClose();
    handleVisibilityToggle();
  };

  const getSelectedList = (models?: ExternalModelSettingsOption[]) => {
    return (
      models
        ?.filter(({ enabled }) => enabled)
        .map(({ model_name }) => model_name) || []
    );
  };

  const defaultTextModelList = useMemo(
    () => [
      ...getSelectedList(textExternalModels.amazon_bedrock),
      ...getSelectedList(textExternalModels.openai),
      ...getSelectedList(textExternalModels.google),
    ],
    [textExternalModels],
  );

  const defaultImageModelList = useMemo(
    () =>
      imageExternalModels
        .filter(({ enabled, model }) => enabled && !model.includes('ninjatech'))
        .map(({ model }) => model),
    [imageExternalModels],
  );

  const modelsLength = useMemo(
    () => defaultTextModelList.length + defaultImageModelList.length,
    [defaultTextModelList, defaultImageModelList],
  );

  return (
    <>
      <ExternalModelHint
        id={HINT_TOOLTIP_ANCHOR_ID}
        isOpen={isHintOpen}
        onOpen={onHintOpen}
        onClose={handleHintClose}
      />

      <div
        id={HINT_TOOLTIP_ANCHOR_ID}
        ref={containerRef}
        className={styles.root}
      >
        <Button
          id={TOOLTIP_ANCHOR_ID}
          color="transparent"
          onClick={handleToggleClick}
        >
          <Icon type="ninjaOutlined" size={SVG_SIZE_M} />

          {!isMobileOrTablet && 'Ninja Agent'}

          {modelsLength > 0 && (
            <Counter withPlus color="black" number={modelsLength} />
          )}

          <CaretUpDown size={14} />
        </Button>

        {isMobileOrTablet ? (
          <BottomDrawer
            isOpen={isVisible}
            onClose={handleVisibilityRemove}
            className={styles.drawer}
          >
            <ExternalModelContainer
              defaultTextModels={defaultTextModelList}
              defaultImageModels={defaultImageModelList}
              onClose={handleVisibilityRemove}
            />
          </BottomDrawer>
        ) : (
          <ReactTooltip
            isOpen={isVisible}
            place={isLandingPage ? 'bottom-end' : 'left'}
            clickable
            className={styles.tooltip}
            noArrow
            anchorSelect={`#${TOOLTIP_ANCHOR_ID}`}
            openOnClick
            offset={isLandingPage ? -250 : -50}
            opacity={1}
          >
            <ExternalModelContainer
              defaultTextModels={defaultTextModelList}
              defaultImageModels={defaultImageModelList}
              onClose={handleVisibilityRemove}
            />
          </ReactTooltip>
        )}
      </div>
    </>
  );
};
