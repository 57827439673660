import styles from './EnterpriseMainSigninSection.module.scss';
import { AppRoutes } from 'src/types';
import { Link } from 'react-router-dom';
import { EnterpriseSigninForm } from 'src/v2/pages/auth/components/enterprise/EnterpriseSigninForm';

export const EnterpriseMainSigninSection = () => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div>
          <h2 className={styles.signInHeader}>Welcome back!</h2>
          <div className={styles.signInSubHeader}>Login to your account</div>
        </div>
        <EnterpriseSigninForm />
        <div className={styles.notMember}>
          <span className={styles.notMemberText}>Don’t have an account?</span>
          &nbsp;
          <Link to={AppRoutes.SIGN_UP} className={styles.loginLink}>
            Sign Up
          </Link>
        </div>

        <div className={styles.notMember}>
          <Link to={AppRoutes.FORGOT_PASSWORD} className={styles.loginLink}>
            Forgot password?
          </Link>
        </div>
      </div>
    </div>
  );
};
