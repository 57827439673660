import { useCollapse } from 'react-collapsed';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import styles from './RelatedQuestions.module.scss';
import { QuestionItem } from './components/QuestionItem';
import { RelatedQuestion } from 'src/types';

const SVG_SIZE = 16;

interface RelatedQuestionsProps {
  data?: RelatedQuestion[];
  defaultExpanded?: boolean;
  title?: string;
}

export const RelatedQuestions = ({
  data,
  defaultExpanded,
  title = 'Related Questions',
}: RelatedQuestionsProps) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    defaultExpanded,
  });

  if (!data) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <button {...getToggleProps()} className={styles.collapseButton}>
          <span>{title}</span>

          {isExpanded ? (
            <CaretUp size={SVG_SIZE} />
          ) : (
            <CaretDown size={SVG_SIZE} />
          )}
        </button>
      </div>

      <div {...getCollapseProps()}>
        {data.map(({ question }, index) => (
          <QuestionItem key={index} question={question} />
        ))}
      </div>
    </div>
  );
};
