import { useSession } from 'src/hooks/useSession';
import { useAppDispatch } from 'src/store';
import {
  updateConversationMessageFeedback,
  updateTaskFeedback,
} from 'src/store/updateQueries';
import {
  useCreateFeedbackMutation,
  useUpdateFeedbackMutation,
  useDeleteFeedbackMutation,
} from 'src/store/services';
import { FeedbackType } from 'src/types';
import { toast } from 'react-toastify';
import log from 'src/utils/logger';
import { Feedback } from 'src/types/models/Feedback';

type FeedbackProps = {
  conversationId: string;
  messageId: string;
  taskId?: string;
  isShowNegativeFeedbackForm: boolean;
  onToggleNegativeFeedbackForm?: () => void;
  feedback?: Feedback;
};

export const useFeedback = ({
  conversationId,
  messageId,
  taskId,
  isShowNegativeFeedbackForm,
  onToggleNegativeFeedbackForm,
  feedback,
}: FeedbackProps) => {
  const { appUser } = useSession();
  const dispatch = useAppDispatch();

  const [createFeedback] = useCreateFeedbackMutation();
  const [updateFeedback] = useUpdateFeedbackMutation();
  const [deleteFeedback] = useDeleteFeedbackMutation();

  // TODO(olha): needs refactoring
  const handleThumbsClick = async (feedbackType: FeedbackType) => {
    if (
      feedbackType === FeedbackType.POSITIVE &&
      isShowNegativeFeedbackForm &&
      !!onToggleNegativeFeedbackForm
    ) {
      onToggleNegativeFeedbackForm();
    }

    try {
      let feedbackId = feedback?.feedback_id || '';
      const previousFeedbackType = feedback?.feedback_type;
      const isTheSameFeedback = previousFeedbackType === feedbackType;
      const requestTaskId = !!taskId && taskId.length > 0 ? taskId : undefined;

      const messageData = {
        user_id: appUser.user_id,
        conversation_id: conversationId,
        message_id: messageId,
        task_id: requestTaskId,
      };

      if (!isTheSameFeedback) {
        if (feedbackId.length > 0) {
          await updateFeedback({
            ...messageData,
            feedback_id: feedbackId,
            feedback_type: feedbackType,
          }).unwrap();
        } else {
          const feedbackResponse = await createFeedback({
            ...messageData,
            feedback_type: feedbackType,
          }).unwrap();

          feedbackId = feedbackResponse?.feedback_id || '';

          const feedback = {
            feedback_id: feedbackId,
            feedback_type: feedbackType,
          };

          dispatch(
            updateConversationMessageFeedback({
              user_id: appUser.user_id,
              conversation_id: conversationId,
              message_id: messageId,
              feedback,
            }),
          );
          if (requestTaskId) {
            dispatch(
              updateTaskFeedback({
                user_id: appUser.user_id,
                task_id: requestTaskId,
                feedback,
              }),
            );
          }
        }

        toast('Feedback submitted');
      } else {
        await deleteFeedback({
          ...messageData,
          feedback_id: feedbackId,
        }).unwrap();

        dispatch(
          updateConversationMessageFeedback({
            user_id: appUser.user_id,
            conversation_id: conversationId,
            message_id: messageId,
            feedback: null,
          }),
        );

        if (requestTaskId) {
          dispatch(
            updateTaskFeedback({
              user_id: appUser.user_id,
              task_id: requestTaskId,
              feedback: null,
            }),
          );
        }

        toast('Feedback canceled');
      }

      if (
        feedbackType === FeedbackType.NEGATIVE &&
        !!onToggleNegativeFeedbackForm &&
        ((isTheSameFeedback && isShowNegativeFeedbackForm) ||
          (!isTheSameFeedback && !isShowNegativeFeedbackForm))
      ) {
        onToggleNegativeFeedbackForm();
      }
    } catch (error) {
      log.error(error);
    }
  };

  return {
    onThumbsClick: handleThumbsClick,
  };
};
