import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useResetAllApiServices } from 'src/store/services';
import { useAppDispatch, resetStore, persistor } from 'src/store';
import { GUEST_ACCESS_TOKEN } from 'src/constants';

export const useResetAllStoredData = () => {
  const dispatch = useAppDispatch();

  const [, setSearchParam] = useSearchParams();

  const { onClearAllApiCaches } = useResetAllApiServices();

  const handleResetData = useCallback(async () => {
    onClearAllApiCaches();

    localStorage.removeItem(GUEST_ACCESS_TOKEN);

    await persistor.purge();

    dispatch(resetStore());

    setSearchParam(new URLSearchParams());
  }, [onClearAllApiCaches, setSearchParam, dispatch]);

  return useMemo(
    () => ({ onResetAllStoredData: handleResetData }),
    [handleResetData],
  );
};
