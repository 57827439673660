import { useMemo } from 'react';
import { useQueryParams } from '../useQueryParams';
import { convertSlugToConversationId } from 'src/utils';
import { DEFAULT_CHAT_ID, TEMPORARY_CONVERSATION_ID } from 'src/constants';

// TODO(olha): divide into two separate hooks: useConversationsList, useCurrentConversation, useConversationActions
export const useConversationParams = () => {
  const {
    searchParams: { conv },
  } = useQueryParams();

  const isTemporaryConversationId = useMemo(
    () => conv?.includes(TEMPORARY_CONVERSATION_ID),
    [conv],
  );
  const currentConversationId = useMemo(
    () =>
      isTemporaryConversationId && conv
        ? conv
        : // TODO(olha): DEFAULT_CHAT_ID is deprecated, should be removed
          convertSlugToConversationId(conv) || DEFAULT_CHAT_ID,
    [isTemporaryConversationId, conv],
  );

  const isLandingPage = useMemo(
    () => currentConversationId === DEFAULT_CHAT_ID,
    [currentConversationId],
  );

  return useMemo(
    () => ({
      isLandingPage,
      currentConversationId,
    }),
    [isLandingPage, currentConversationId],
  );
};
