import { useGetUserByIdQuery } from 'src/store/services';
import { useSession } from '../useSession';
import { useUserData } from '../useUserData';
import { useCallback, useMemo } from 'react';

export interface Metadata {
  external_model_onboarded?: boolean;
}

// This hook for temporary variables of flags since metadata type is any
export const useUserMetadata = () => {
  const {
    appUser: { user_id },
  } = useSession();

  const { updateUserData } = useUserData();

  const { metadata } = useGetUserByIdQuery(user_id, {
    skip: !user_id,
    selectFromResult: ({ data }) => ({
      metadata: data?.metadata,
    }),
  });

  const updateMetadata = useCallback(
    async (updatedMetadata: Metadata) => {
      await updateUserData(
        {
          metadata: {
            ...metadata,
            ...updatedMetadata,
          },
        },
        false,
      );
    },
    [metadata, updateUserData],
  );

  return useMemo(
    () => ({
      metadata,
      updateMetadata,
    }),
    [metadata, updateMetadata],
  );
};
