import { useMemo } from 'react';
import {
  ExternalModelTabContent,
  ResearchCard,
  SearchWebPageReference,
} from 'src/types';
import { Icon as PhosphorIconType, HeadCircuit } from '@phosphor-icons/react';
import { Tabs, TabList, TabPanel, Tab } from 'src/v2/commonComponents/Tabs';
import { Icon } from 'src/components/Icon';
import styles from './ThreadResearchCard.module.scss';
import { SVG_SIZE_M } from 'src/constants';
import { prepareExternalModelsTabContent } from 'src/utils';

// TODO(olha): needs refactoring
import { ThreadMessageContent } from 'src/components/FlatAppearance/components/ThreadMessageContent';
import { ProgressBar } from 'src/components/FlatAppearance/components/ThreadCombinedCard/components/ProgressBar';
import { ThreadResearchReferences } from 'src/components/FlatAppearance/components/ThreadResearchCard/components/ThreadResearchReferences';
import { ThreadResearchMedia } from 'src/components/FlatAppearance/components/ThreadResearchCard/components/ThreadResearchMedia';
import { SuperAgentUpsell } from './components/SuperAgentUpsell';

interface TabData extends ExternalModelTabContent {
  references?: SearchWebPageReference[];
  IconComponent?: PhosphorIconType;
}

enum ResearchTabs {
  SEARCH_RESULT = 'search_result',
  DEEP_RESEARCH = 'deep_research',
}

interface ThreadResearchCardProps {
  researchCard?: ResearchCard;
  isStreaming?: boolean;
}

export const ThreadResearchCard = ({
  researchCard,
  isStreaming,
}: ThreadResearchCardProps) => {
  const { search_result, deep_research_result, generative_ai_result } =
    researchCard?.data || {};

  const { referenced_model_summary } = generative_ai_result || {};
  const { references: external_models_result } = referenced_model_summary || {};
  const { web_page_references, image_references, video_references } =
    search_result || {};

  const defaultTab = useMemo(
    () =>
      !isStreaming && deep_research_result
        ? ResearchTabs.DEEP_RESEARCH
        : ResearchTabs.SEARCH_RESULT,
    [isStreaming, deep_research_result],
  );

  const tabsData: TabData[] = useMemo(
    () => [
      ...(search_result
        ? [
            {
              tabName: ResearchTabs.SEARCH_RESULT,
              iconName: 'ninjaOutlined',
              tabTitle: 'Ninja',
              content: search_result.summary || '',
              references: web_page_references,
            },
          ]
        : []),
      {
        tabName: ResearchTabs.DEEP_RESEARCH,
        IconComponent: HeadCircuit,
        tabTitle: 'Ninja SuperAgent',
        content: deep_research_result?.summary || '',
        references: deep_research_result?.web_page_references,
      },
      ...(external_models_result
        ? prepareExternalModelsTabContent(external_models_result).map(
            ({ tabName, tabTitle, iconName, content }) => ({
              tabName,
              iconName,
              tabTitle,
              content,
            }),
          )
        : []),
    ],
    [
      search_result,
      web_page_references,
      deep_research_result,
      external_models_result,
    ],
  );

  if (!researchCard) {
    return null;
  }

  return (
    <div className={styles.root}>
      <Tabs<string> defaultActiveTab={defaultTab}>
        <TabList>
          {tabsData.map(({ tabName, iconName, IconComponent, tabTitle }) => (
            <Tab key={tabName} tab={tabName} className={styles.tab}>
              {iconName ? (
                <Icon type={iconName} size={SVG_SIZE_M} />
              ) : IconComponent ? (
                <IconComponent size={SVG_SIZE_M} />
              ) : null}

              <span>{tabTitle}</span>
            </Tab>
          ))}
        </TabList>

        {tabsData.map(({ tabName, content, references }) => (
          <TabPanel key={tabName} tab={tabName}>
            {content.length === 0 ? (
              <>
                {(tabName !== ResearchTabs.DEEP_RESEARCH ||
                  !!deep_research_result) && <ProgressBar count={3} />}
                {tabName === ResearchTabs.DEEP_RESEARCH && <SuperAgentUpsell />}
              </>
            ) : (
              <div className={styles.container}>
                <ThreadMessageContent
                  content={content}
                  isStreaming={isStreaming}
                  references={references}
                />

                {references && (
                  <ThreadResearchReferences researchReferences={references} />
                )}

                {tabName === ResearchTabs.SEARCH_RESULT && (
                  <ThreadResearchMedia
                    images={image_references || []}
                    videos={video_references || []}
                  />
                )}
              </div>
            )}
          </TabPanel>
        ))}
      </Tabs>
    </div>
  );
};
