import { lazy, Suspense, useMemo, useRef } from 'react';
import cn from 'classnames';
import { Sliders } from '@phosphor-icons/react';
import {
  useOutsideClick,
  useSession,
  useUserData,
  useThemeActions,
  useRightPanelState,
} from 'src/hooks';
import styles from './ModelsMenu.module.scss';
import { ONBOARDING_HINT_STEP, SVG_SIZE_M } from 'src/constants';
import { sendGTMEvent } from 'src/utils';
import { ChatModeSidePanel, GTMEvent } from 'src/types';

const LazyReactTooltip = lazy(() =>
  import('react-tooltip').then((module) => ({ default: module.Tooltip })),
);

export const ModelsMenu = () => {
  const onboardingHintStep = localStorage.getItem(ONBOARDING_HINT_STEP);
  const containerRef = useRef<HTMLDivElement>(null);
  const containerOnboardingHintRef = useRef<HTMLDivElement>(null);
  const { onboardingHintsStep, changeOnboardingHintStep, appUser } =
    useSession();
  const { updateUserData } = useUserData();
  const { toggleSettingsPanelExpanded, updateChatModeSidePanel } =
    useThemeActions();
  const { isSettingsPanelExpanded } = useRightPanelState();

  const handleGTMEventClick = () => {
    sendGTMEvent(GTMEvent.NINJA_AGENTS_CLICK);
    toggleSettingsPanelExpanded();
    updateChatModeSidePanel(ChatModeSidePanel.NINJA_AGENTS);
  };

  const isShowHint =
    onboardingHintsStep === 1 && !onboardingHintStep && !appUser.onboarded;

  // TODO(olha): double check if we still need it
  const handleOutsideOnboardingTooltipClick = async () => {
    if (onboardingHintsStep === 1 && !appUser.onboarded) {
      changeOnboardingHintStep(0);
      await updateUserData(
        {
          onboarded: true,
        },
        false,
      );
      localStorage.setItem(ONBOARDING_HINT_STEP, '0');
    }
  };

  useOutsideClick(containerOnboardingHintRef, () =>
    handleOutsideOnboardingTooltipClick(),
  );

  const countOfNinjaAgentsSelectedItems = useMemo(() => {
    return (
      Number(appUser?.settings?.research?.deep_research?.enabled) +
      Number(appUser?.settings?.research?.web_search?.search_images) +
      Number(appUser?.settings?.research?.web_search?.search_videos)
    );
  }, [
    appUser?.settings?.research?.deep_research?.enabled,
    appUser?.settings?.research?.web_search?.search_images,
    appUser?.settings?.research?.web_search?.search_videos,
  ]);

  return (
    <div className={styles.root} ref={containerRef}>
      <button
        onClick={handleGTMEventClick}
        id="models-menu-trigger"
        className={cn(styles.triggerButton, {
          [styles.active]: isSettingsPanelExpanded,
        })}
        data-tooltip-id="externsal-models-onboarding-tooltip"
        data-tooltip-place="top-end"
      >
        {countOfNinjaAgentsSelectedItems > 0 && (
          <span className={styles.counter}>
            {countOfNinjaAgentsSelectedItems}
          </span>
        )}
        <Sliders size={SVG_SIZE_M} />
      </button>

      <div ref={containerOnboardingHintRef}>
        <Suspense>
          <LazyReactTooltip
            id="externsal-models-onboarding-tooltip"
            noArrow={true}
            className="nj-onboarding-bubble-tooltip external-models"
            isOpen={isShowHint}
            imperativeModeOnly={true}
            openOnClick
          >
            <span>Welcome! Set your preferences for Ninja agent.</span>
          </LazyReactTooltip>
        </Suspense>
      </div>
    </div>
  );
};
