import { useMemo } from 'react';
import { useSession } from 'src/hooks';
import { LabelPro } from 'src/components/LabelPro';
import { LabelFree } from 'src/components/LabelFree';
import { LabelProTrial } from 'src/components/FlatAppearance/components/LabelProTrial';
import { LabelEnterpriseTrial } from 'src/components/LabelEnterpriseTrial';
import { LabelUltra } from 'src/components/LabelUltra';
import LabelStandard from '../LabelStandard/LabelStandard';
import { LabelEnterprise } from 'src/components/LabelEnterprise';

export const CurrentPlanLabel = () => {
  const {
    isUltraTier,
    isProTier,
    isFreeTier,
    isEnterpriseTrialTier,
    isEnterpriseTier,
    isProTrialTier,
    isStandardTier,
  } = useSession();

  const currentPlan = useMemo(() => {
    if (isFreeTier) {
      return <LabelFree />;
    }
    if (isProTrialTier) {
      return <LabelProTrial />;
    }
    if (isProTier) {
      return <LabelPro labelType="full" />;
    }
    if (isEnterpriseTrialTier) {
      return <LabelEnterpriseTrial />;
    }
    if (isEnterpriseTier) {
      return <LabelEnterprise />;
    }
    if (isUltraTier) {
      return <LabelUltra labelType="full" />;
    }
    if (isStandardTier) {
      return <LabelStandard labelType="full" />;
    }
    return null;
  }, [
    isFreeTier,
    isProTrialTier,
    isProTier,
    isEnterpriseTrialTier,
    isEnterpriseTier,
    isUltraTier,
    isStandardTier,
  ]);

  return currentPlan;
};
