import {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import { TabListProps } from './components/TabList';
import { TabPanelProps } from './components/TabPanel';

interface TabsProps<T extends string> {
  className?: string;
  defaultActiveTab: T;
  children: ReactNode;
}

export const Tabs = <T extends string>({
  className,
  defaultActiveTab,
  children,
}: TabsProps<T>) => {
  const [activeTab, setActiveTab] = useState<T>(defaultActiveTab);

  useEffect(() => {
    setActiveTab(defaultActiveTab);
  }, [defaultActiveTab]);

  const handleTabClick = (tab: T) => {
    setActiveTab(tab);
  };

  return (
    <div className={className}>
      {Children.map(children, (child) => {
        if (!isValidElement(child)) {
          return null;
        }

        const childType = (child.type as React.FC).displayName;

        if (childType === 'TabList') {
          return cloneElement(child as ReactElement<TabListProps<T>>, {
            activeTab,
            onTabClick: handleTabClick,
          });
        }

        if (childType === 'TabPanel') {
          return cloneElement(child as ReactElement<TabPanelProps<T>>, {
            activeTab,
          });
        }

        return child;
      })}
    </div>
  );
};
