import { useState } from 'react';
import { useExternalModels, useSession } from 'src/hooks';
import { ModelLists } from '../ModelLists';
import { SubmitActions } from '../SubmitActions';
import styles from './ExternalModelContainer.module.scss';
import { ExternalModelSettings, ExternalModelSettingsOption } from 'src/types';
import { Button } from 'src/v2/commonComponents/Button';
import { X } from '@phosphor-icons/react';
import { SVG_SIZE_S } from 'src/constants';

interface ExternalModelContainerProps {
  defaultTextModels: string[];
  defaultImageModels: string[];
  onClose: () => void;
}

export const ExternalModelContainer = ({
  defaultTextModels,
  defaultImageModels,
  onClose,
}: ExternalModelContainerProps) => {
  const { isFreeTier, isOpenTier, isStandardTier } = useSession();

  const { textExternalModels, imageExternalModels, updateExternalModels } =
    useExternalModels();

  const [selectedTextModels, setSelectedTextModels] =
    useState<string[]>(defaultTextModels);

  const [selectedImageModels, setSelectedImageModels] =
    useState<string[]>(defaultImageModels);

  const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(true);

  const getUpdatedList = (selectedList: string[], triggeredItem: string) => {
    if (isSubmitDisabled) {
      setSubmitDisabled(false);
    }

    if (selectedList.includes(triggeredItem)) {
      return selectedList.filter((item) => item !== triggeredItem);
    } else {
      return selectedList.slice(0, 1).concat(triggeredItem);
    }
  };

  const handleTextModelClick = (textModel: string) => {
    setSelectedTextModels((prev) => getUpdatedList(prev, textModel));
  };

  const handleImageModelClick = (imageModel: string) => {
    setSelectedImageModels((prev) => getUpdatedList(prev, imageModel));
  };

  const handleReset = () => {
    if (isSubmitDisabled) {
      setSubmitDisabled(false);
    }

    setSelectedTextModels([]);
    setSelectedImageModels([]);
  };

  const updateEnablingTextModel = (models?: ExternalModelSettingsOption[]) => {
    return models?.map((item) => ({
      ...item,
      enabled: selectedTextModels.includes(item.model_name),
    }));
  };

  const handleSubmit = () => {
    setSubmitDisabled(true);

    const updatedTextModels: Partial<ExternalModelSettings> = {
      amazon_bedrock: updateEnablingTextModel(
        textExternalModels.amazon_bedrock,
      ),
      openai: updateEnablingTextModel(textExternalModels.openai),
      google: updateEnablingTextModel(textExternalModels.google),
    };

    const updatedImageModels = imageExternalModels.map((item) => ({
      ...item,
      enabled: item.model.includes('ninjatech')
        ? item.enabled
        : selectedImageModels.includes(item.model),
    }));

    updateExternalModels({
      textExternalModels: updatedTextModels,
      imageExternalModels: updatedImageModels,
    });

    onClose();
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <p className={styles.title}>AI model access</p>
        <p className={styles.subtitle}>
          Select up to two AI models for each category
        </p>

        <Button
          className={styles.closeButton}
          shape="round"
          color="transparent"
          onClick={onClose}
        >
          <X size={SVG_SIZE_S} />
        </Button>
      </div>

      <ModelLists
        selectedTextModels={selectedTextModels}
        selectedImageModels={selectedImageModels}
        onTextModelClick={handleTextModelClick}
        onImageModelClick={handleImageModelClick}
      />

      <div>
        <hr className="divider" />
        <SubmitActions
          onReset={handleReset}
          onSubmit={handleSubmit}
          isSubmitDisabled={isSubmitDisabled}
          isResetDisabled={
            isFreeTier ||
            isOpenTier ||
            isStandardTier ||
            (selectedTextModels.length === 0 &&
              selectedImageModels.length === 0)
          }
        />
      </div>
    </div>
  );
};
