import {
  ExternalModelItem,
  ExternalModelItemProps,
} from '../ExternalModelItem';
import styles from './ExternalModelGroup.module.scss';

interface ExternalModelGroupProps {
  groupTitle: string;
  modelItems: ExternalModelItemProps[];
}

export const ExternalModelGroup = ({
  groupTitle,
  modelItems,
}: ExternalModelGroupProps) => {
  if (modelItems.length === 0) {
    return null;
  }

  return (
    <div>
      <p className={styles.groupTitle}>{groupTitle}</p>

      {modelItems.map((item) => (
        <ExternalModelItem key={item.id} {...item} />
      ))}
    </div>
  );
};
