import React, { useState } from 'react';
import styles from './TextList.module.scss';
import cn from 'classnames';

interface TextListProps {
  values: { [key: string]: string }; // Dictionary of values (id: name)
  onValueSelect: (id: string) => void; // Callback when a language is selected
  defaultValue?: string; // Optional default value id
}

export const TextList = ({
  values,
  onValueSelect,
  defaultValue,
}: TextListProps) => {
  const initialLanguage = defaultValue || Object.keys(values)[0];
  const [selectedLanguage, setSelectedLanguage] = useState<string>(() => {
    return initialLanguage;
  });

  const handleClick = (id: string) => {
    setSelectedLanguage(id);
    onValueSelect(id);
  };

  return (
    <ul className={styles.list}>
      {Object.entries(values).map(([id, name]) => (
        <li
          key={id}
          onClick={() => handleClick(id)}
          className={cn(styles.listItem, {
            [styles.selected]: selectedLanguage === id,
          })}
        >
          <span>{name}</span>
          {id === defaultValue && (
            <span className={styles.defaultLabel}>Default</span>
          )}
          {selectedLanguage === id && (
            <span className={styles.selectionMarker}></span>
          )}
        </li>
      ))}
    </ul>
  );
};
