import { FormInput } from 'src/components/FormInput';
import { FormProvider } from 'src/components/FormProvider';

import styles from './EnterpriseSignupForm.module.scss';
import { useForm } from 'react-hook-form';
import { ReactNode, useCallback, useState } from 'react';
import {
  USER_NAME_PATTERN_2,
  ENTERPRISE_USER_VALID_NAME_PATTERN,
} from 'src/constants';
import { v4 as uuid_v4 } from 'uuid';
import { signUp } from 'aws-amplify/auth';
import { sendGTMEvent } from 'src/utils';
import { AppRoutes, GTMEvent } from 'src/types';
import { ServiceError } from '@aws-amplify/core/src/types/errors';
import { PasswordHint } from 'src/pages/AuthPages/components/PasswordHint';
import { EmailVerificationBlock } from 'src/pages/AuthPages/components/EmailVerificationBlock';
import { Link, useNavigate } from 'react-router-dom';
import { ErrorMessageBlock } from 'src/pages/AuthPages/components/ErrorMessageBlock';
import {
  COGNITO_PASSWORD_NUMBER_OF_CHARS_RULE,
  COGNITO_PASSWORD_NUMBER_SPECIAL_CHARS_RULE,
  COGNITO_PASSWORD_UPPER_AND_LOWER_CHARS_RULE,
  COGNITO_PRE_SIGNUP_ERROR_PREFIX,
} from 'src/constants/cognito';
import {
  ENTERPRISE_INVALID_DOMAIN_MESSAGE,
  ENTERPRISE_VALID_EMAIL_DOMAINS,
} from 'src/constants/enterprise';
import { useLazyCheckEmailInfoQuery } from 'src/store/services';
import classNames from 'classnames';
import { EmailVerificationHint } from 'src/v2/pages/auth/components/EmailVerificationHint/EmailVerificationHint';

enum SignupFields {
  EMAIL = 'email',
  FIRSTNAME = 'firstname',
  LASTNAME = 'lastname',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirm_password',
}

interface FormData {
  [SignupFields.EMAIL]: string;
  [SignupFields.FIRSTNAME]: string;
  [SignupFields.LASTNAME]: string;
  [SignupFields.PASSWORD]: string;
  [SignupFields.CONFIRM_PASSWORD]: string;
}

export const EnterpriseSignupForm = () => {
  const [errorMessage, setErrorMessage] = useState<string | ReactNode | null>(
    null,
  );
  const [emailErrorMessage, setEmailErrorMessage] = useState<
    string | ReactNode | null
  >(null);
  const [passwordRulesState, setPasswordRulesState] = useState<
    Record<number, boolean>
  >({});
  const navigate = useNavigate();
  const [checkEmailInfo] = useLazyCheckEmailInfoQuery();
  const methods = useForm<FormData>({
    defaultValues: {
      [SignupFields.EMAIL]: '',
      [SignupFields.FIRSTNAME]: '',
      [SignupFields.LASTNAME]: '',
      [SignupFields.PASSWORD]: '',
      [SignupFields.CONFIRM_PASSWORD]: '',
    },
    mode: 'onChange',
  });
  const {
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { isValid, isSubmitting },
  } = methods;

  const submitForm = async (data: FormData) => {
    try {
      const firstName = data[SignupFields.FIRSTNAME];
      const lastName = data[SignupFields.LASTNAME];

      if (data[SignupFields.PASSWORD] !== data[SignupFields.CONFIRM_PASSWORD]) {
        setErrorMessage('Passwords do not match. Please try again.');
        return;
      }
      setErrorMessage('');
      const userName = `Plain_${uuid_v4()}`;
      await signUp({
        username: userName,
        password: data[SignupFields.PASSWORD],
        options: {
          userAttributes: {
            email: data[SignupFields.EMAIL].toLowerCase(),
            given_name: firstName,
            family_name: lastName,
          },
        },
      });
      sendGTMEvent(GTMEvent.EMAIL_SIGNUP_SUCCESS);

      reset();
      navigate(
        `/verified?email=${encodeURIComponent(data[SignupFields.EMAIL].toLowerCase())}`,
        { replace: true },
      );
    } catch (error) {
      sendGTMEvent(GTMEvent.EMAIL_SIGNUP_FAILURE);

      let errorMessage = error ? (error as ServiceError).message : 'error';
      if (errorMessage.startsWith(COGNITO_PRE_SIGNUP_ERROR_PREFIX)) {
        errorMessage = errorMessage.replace(
          COGNITO_PRE_SIGNUP_ERROR_PREFIX,
          '',
        );
      }

      switch ((error as ServiceError).name) {
        case 'InvalidPasswordException':
          setErrorMessage(
            <span>
              Password does not meet required criteria. Please{' '}
              <PasswordHint text="Review" /> and try again.
            </span>,
          );
          return;
        case 'NotAuthorizedException':
        case 'UsernameExistsException':
          setErrorMessage(errorMessage);
          return;
        case 'UserLambdaValidationException':
          if (errorMessage.includes('Your email has not been verified yet')) {
            setErrorMessage(
              <EmailVerificationBlock
                email={data[SignupFields.EMAIL].toLowerCase()}
              />,
            );
          } else if (
            errorMessage.includes('You already signed up with this email.')
          ) {
            setErrorMessage(
              <span>
                The Ninja account already exists. Head back to{' '}
                <Link to={AppRoutes.LOGIN}>log in.</Link>
              </span>,
            );
          } else {
            // For some reason Cognito adds a dot at the end
            setErrorMessage(errorMessage.replace('..', '.'));
          }
          return;
        default:
          setErrorMessage(error ? (error as ServiceError).message : 'error');
          return;
      }
    }
  };

  const emailValidator = useCallback(
    async (data: string) => {
      const userEmail = data.toLowerCase();
      const emailDomain = data.split('@')[1];
      if (!ENTERPRISE_VALID_EMAIL_DOMAINS.includes(emailDomain)) {
        setError(SignupFields.EMAIL, {
          message: ENTERPRISE_INVALID_DOMAIN_MESSAGE,
        });
        return;
      }
      try {
        const { user_exists, user_email_verified } =
          await checkEmailInfo(userEmail).unwrap();
        if (user_exists) {
          if (user_email_verified) {
            setEmailErrorMessage(
              <span>
                The Ninja account already exists. Please head back to{' '}
                <Link to={AppRoutes.LOGIN}>log in.</Link>
              </span>,
            );
          } else {
            setEmailErrorMessage(<EmailVerificationHint email={userEmail} />);
          }
          return;
        }
      } catch (error) {
        console.warn('Unable to get email info');
      }

      clearErrors([SignupFields.EMAIL]);
      setEmailErrorMessage(null);
    },
    [checkEmailInfo, setError, clearErrors],
  );

  const passwordValidator = useCallback((data: string) => {
    setPasswordRulesState({
      0: COGNITO_PASSWORD_NUMBER_OF_CHARS_RULE.test(data),
      1: COGNITO_PASSWORD_UPPER_AND_LOWER_CHARS_RULE.test(data),
      2: COGNITO_PASSWORD_NUMBER_SPECIAL_CHARS_RULE.test(data),
    });
  }, []);

  const nameValidationRules = {
    minLength: {
      value: 2,
      message: ENTERPRISE_USER_VALID_NAME_PATTERN.message,
    },
    maxLength: {
      value: 100,
      message: ENTERPRISE_USER_VALID_NAME_PATTERN.message,
    },
    validate: (value: string) => {
      if (!ENTERPRISE_USER_VALID_NAME_PATTERN.value.test(value)) {
        return ENTERPRISE_USER_VALID_NAME_PATTERN.message;
      } else if (USER_NAME_PATTERN_2.value.test(value)) {
        return USER_NAME_PATTERN_2.message;
      }
      return true;
    },
  };

  return (
    <FormProvider<FormData> methods={methods}>
      <form onSubmit={handleSubmit(submitForm)} className={styles.root}>
        <div className={styles.formRow}>
          <legend className={styles.legend}>Email</legend>
          <FormInput
            name={SignupFields.EMAIL}
            type="email"
            className={styles.inputField}
            autoComplete="username"
            placeholder="username@amazon.com"
            required={true}
            onBlurHandler={emailValidator}
            errorRenderType="inline"
            errorMessage={emailErrorMessage}
          />
        </div>
        <div className={styles.formRow}>
          <legend className={styles.legend}>First Name</legend>
          <FormInput
            name={SignupFields.FIRSTNAME}
            type="text"
            className={styles.inputField}
            placeholder="Enter first name"
            required={true}
            errorRenderType="inline"
            rules={nameValidationRules}
          />
        </div>
        <div className={styles.formRow}>
          <legend className={styles.legend}>Last Name</legend>
          <FormInput
            name={SignupFields.LASTNAME}
            type="text"
            className={styles.inputField}
            placeholder="Enter last name"
            required={true}
            errorRenderType="inline"
            rules={nameValidationRules}
          />
        </div>
        <div className={styles.formRow}>
          <legend className={styles.legend}>Password</legend>

          <ul className={styles.passwordRules}>
            <li
              className={classNames({
                [styles.passwordRulePassed]: passwordRulesState[0],
              })}
            >
              Minimum 8 characters
            </li>
            <li
              className={classNames({
                [styles.passwordRulePassed]: passwordRulesState[1],
              })}
            >
              Include uppercase and lowercase letters
            </li>
            <li
              className={classNames({
                [styles.passwordRulePassed]: passwordRulesState[2],
              })}
            >
              Include numbers and special characters
            </li>
          </ul>

          <FormInput
            name={SignupFields.PASSWORD}
            type="password"
            autoComplete="new-password"
            className={styles.inputField}
            placeholder="Create password"
            required={true}
            onChangeHandler={passwordValidator}
          />
        </div>
        <div className={styles.formRow}>
          <legend className={styles.legend}>Confirm password</legend>
          <FormInput
            name={SignupFields.CONFIRM_PASSWORD}
            type="password"
            className={styles.inputField}
            placeholder="Confirm password"
            required={true}
          />
        </div>
        <button
          className={styles.submitButton}
          type="submit"
          disabled={!isValid || !!emailErrorMessage || isSubmitting}
        >
          <span>Sign up</span>
        </button>
        {!!errorMessage && <ErrorMessageBlock errorMessage={errorMessage} />}
      </form>
    </FormProvider>
  );
};
