import { CompanyLinksSet } from 'src/pages/AuthPages/components/CompanyLinksSet';
import { useSession } from 'src/hooks';
import { LabelUltra } from 'src/components/LabelUltra';
import { GuestAuthLinks } from 'src/pages/AuthPages/components/GuestAuthLinks';
import { UpgradeLearnMoreButtons } from 'src/pages/AuthPages/components/UpgradeLearnMoreButtons';
import { LabelEnterpriseTrial } from 'src/components/LabelEnterpriseTrial';
import { PaypalPaymentInProgressPanel } from 'src/components/PaypalPaymentInProgressPanel';
import { PAYPAL_PAYMENT_IN_PROGRESS } from 'src/constants';
import { getWithExpiry } from 'src/utils/localStorage';
import { LabelEnterprise } from 'src/components/LabelEnterprise';

export const TiersAuthFooter = () => {
  const {
    isFreeTier,
    isProTrialTier,
    isOpenTier,
    isProTier,
    isEnterpriseTier,
    isEnterpriseTrialTier,
    isUltraTier,
    isStandardTier,
  } = useSession();

  const paypalPaymentInProgress =
    getWithExpiry(PAYPAL_PAYMENT_IN_PROGRESS) === 'true';

  const renderContent = () => {
    if (isOpenTier) {
      return (
        <>
          <h4 className="nj-tier-auth-footer-title">
            <span>Sign Up For Free</span>
          </h4>

          <p className="nj-tier-auth-footer-description">
            Get access to your own AI assistant for research, writing, coding,
            image generation, scheduling and so much more. No credit card
            required.
          </p>

          <GuestAuthLinks dataGTM="Left-panel-signup" />
        </>
      );
    }

    if (paypalPaymentInProgress) {
      return <PaypalPaymentInProgressPanel />;
    }

    if (isFreeTier || isProTrialTier) {
      return (
        <>
          <h4 className="nj-tier-auth-footer-title">
            <span>Unlimited Tasks, Starting at $5/mo</span>
          </h4>

          <p className="nj-tier-auth-footer-description">
            Enjoy unlimited daily tasks, custom image settings, and access to
            the best AI models
          </p>

          <UpgradeLearnMoreButtons dataGTM="Left-panel-upgrade" />
        </>
      );
    }

    if (isStandardTier) {
      return (
        <>
          <h4 className="nj-tier-auth-footer-title">
            <span>Unlimited external AI models</span>
          </h4>

          <p className="nj-tier-auth-footer-description">
            Get answers from external models from companies like OpenAI, Google,
            and Anthropic
          </p>

          <UpgradeLearnMoreButtons dataGTM="Left-panel-upgrade" />
        </>
      );
    }

    if (isProTier) {
      return (
        <>
          <h4 className="nj-tier-auth-footer-title">
            <span>Unlimited external AI models</span>
          </h4>

          <p className="nj-tier-auth-footer-description">
            Get answers from external models from companies like OpenAI, Google,
            and Anthropic
          </p>

          <UpgradeLearnMoreButtons dataGTM="Left-panel-upgrade" />
        </>
      );
    }

    if (isUltraTier) {
      return (
        <div className="nj-tier-auth-footer-current-plan-row">
          <span>Current plan:</span>
          <LabelUltra />
        </div>
      );
    }

    if (isEnterpriseTrialTier) {
      return (
        <div className="nj-tier-auth-footer-current-plan-row">
          <span>Current plan:</span>
          <LabelEnterpriseTrial />
        </div>
      );
    }

    if (isEnterpriseTier) {
      return (
        <div className="nj-tier-auth-footer-current-plan-row">
          <span>Current plan:</span>
          <LabelEnterprise />
        </div>
      );
    }

    return <></>;
  };
  return (
    <div className="nj-tier-auth-footer">
      {renderContent()}

      <div className="nj-tier-auth-footer-links-wrapper">
        <CompanyLinksSet />
      </div>
    </div>
  );
};
