import logoHorizontal from 'src/images/logos/logo-light.svg';
import { Link } from 'react-router-dom';
import styles from './Header.module.scss';
import { AppRoutes } from 'src/types';
import {
  SEO_LOGIN_DESCRIPTION,
  SEO_LOGIN_TITLE,
  SVG_SIZE_L,
  UI_DOMAIN,
  SEO_SIGN_UP_TITLE,
  SEO_SIGN_UP_DESCRIPTION,
} from 'src/constants';
import { ArrowLeft } from '@phosphor-icons/react';
import { Helmet } from 'react-helmet-async';
import { isEnterprise } from 'src/utils';

interface HeaderProps {
  isSignUp?: boolean;
  addSEOTags?: boolean;
}

export const Header = ({ isSignUp, addSEOTags = false }: HeaderProps) => {
  const title = isSignUp ? SEO_SIGN_UP_TITLE : SEO_LOGIN_TITLE;
  const description = isSignUp
    ? SEO_SIGN_UP_DESCRIPTION
    : SEO_LOGIN_DESCRIPTION;

  return (
    <header className={styles.root}>
      {addSEOTags && (
        <Helmet prioritizeSeoTags>
          <title>{title}</title>
          <meta content={description} name="description" data-rh="true" />
          <meta content={title} property="og:title" />
          <meta
            content={description}
            property="og:description"
            data-rh="true"
          />
          <meta content={title} property="twitter:title" data-rh="true" />
          <meta
            content={description}
            property="twitter:description"
            data-rh="true"
          />
        </Helmet>
      )}
      <Link to="/" className={styles.logo}>
        <img src={logoHorizontal} alt="Logo" />
      </Link>
      {!isEnterprise && (
        <Link to={AppRoutes.HOME} className={styles.backToHome}>
          <span className={styles.backToHomeLink}>
            <ArrowLeft size={SVG_SIZE_L} />
            Back to {UI_DOMAIN}
          </span>
        </Link>
      )}
    </header>
  );
};
