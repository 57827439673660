import { useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ManageTasksChatPage } from 'src/pages/ManageTasksChatPage';
import { AccessModal } from 'src/pages/AuthPages/components/AccessModal';
import { useGoogleResourcesAccess, useSession } from 'src/hooks';
import { UserStatus } from 'src/types/models/UserStatus';
import { PAYPAL_PAYMENT_IN_PROGRESS, VERSION_NUMBER } from 'src/constants';
import { MaintenanceScreen } from 'src/pages/AuthPages/components/MaintenanceScreen';
import { QueueScreen } from 'src/pages/AuthPages/components/QueueScreen';
import { UpdatesModal } from 'src/pages/AuthPages/components/UpdatesModal';
import {
  AppRoutes,
  BillingSettingsNestedRoutes,
  UserSettingsNestedRoutes,
} from 'src/types';
import { ManageAccountPage } from 'src/pages/ManageAccountPage';
import { SubscriptionPlans } from 'src/pages/ManageAccountPage/components/SubscriptionPlans';
import { SubscriptionsBilling } from 'src/pages/ManageAccountPage/components/SubscriptionsBilling';
import { UpdatePlanModal } from 'src/components/UpdatePlanModal';
import { useCanConnectToGoogle } from 'src/hooks/useCanConnectToGoogle';
import { env } from 'src/env';
import { Profile } from 'src/pages/ManageAccountPage/components/Profile';
import { Integrations } from 'src/pages/ManageAccountPage/components/Integrations';
import { General } from 'src/pages/ManageAccountPage/components/General';
import { ContactNinjaModal } from 'src/components/ContactNinjaModal';
import { PaymentBetaPage } from 'src/pages/PaymentBetaPage';
import { getWithExpiry } from 'src/utils/localStorage';
import { CancelPaypalSubscriptionModal } from 'src/components/CancelPaypalSubscriptionModal';

const ENTERPRISE_PRIVATE_ROUTES = [AppRoutes.HOME, AppRoutes.MANAGE_ACCOUNT];

const ENTERPRISE_MANAGE_ACCOUNT_ROUTES: string[] = [
  UserSettingsNestedRoutes.PROFILE,
  UserSettingsNestedRoutes.INTEGRATIONS,
  UserSettingsNestedRoutes.GENERAL,
  BillingSettingsNestedRoutes.SUBSCRIPTION,
];

const defaultRoutes = [
  {
    path: AppRoutes.HOME,
    element: <ManageTasksChatPage />,
  },
  {
    path: AppRoutes.PAYMENT,
    element: <PaymentBetaPage />,
  },
  {
    path: AppRoutes.MANAGE_ACCOUNT,
    element: <ManageAccountPage />,
    children: [
      {
        index: true,
        element: <SubscriptionPlans />,
      },
      {
        path: UserSettingsNestedRoutes.PROFILE,
        element: <Profile />,
      },
      {
        path: UserSettingsNestedRoutes.INTEGRATIONS,
        element: <Integrations />,
      },
      {
        path: UserSettingsNestedRoutes.GENERAL,
        element: <General />,
      },
      {
        path: BillingSettingsNestedRoutes.SUBSCRIPTION,
        element: <SubscriptionPlans />,
      },
      {
        path: BillingSettingsNestedRoutes.BILLING,
        element: <SubscriptionsBilling />,
      },
    ],
  },
];

export const PrivateContent = () => {
  const [isShowUpdatesModal, setIsShowUpdatesModal] = useState(false);
  const [maintenanceMode, setMaintenanceMode] = useState(false);

  const {
    appUser,
    isFirstSchedulerTask,
    isAccessModalShown,
    updateIsAccessModalShown,
    isEnterpriseTier,
  } = useSession();

  const canConnectToGoogle = useCanConnectToGoogle();

  const {
    canAccessDirectory,
    canAccessContacts,
    canAccessCalendar,
    isLoadingConnectionCheck,
    isFetchingConnectionCheck,
  } = useGoogleResourcesAccess();

  const isShowAccess =
    canConnectToGoogle &&
    isFirstSchedulerTask &&
    !isAccessModalShown &&
    !isLoadingConnectionCheck &&
    !isFetchingConnectionCheck &&
    !!appUser?.user_id &&
    (!canAccessDirectory || !canAccessContacts || !canAccessCalendar);

  useEffect(() => {
    // only show the updates modal in beta and production
    if (
      env.REACT_APP_ENVIRONMENT === 'beta' ||
      env.REACT_APP_ENVIRONMENT === 'gamma' ||
      env.REACT_APP_ENVIRONMENT === 'production'
    ) {
      if (
        !!appUser?.metadata?.app_version &&
        appUser?.metadata?.app_version !== VERSION_NUMBER
      ) {
        setIsShowUpdatesModal(true);
      }
    }
  }, [appUser?.metadata?.app_version, appUser?.user_id]);

  // solely to remove this key if it's expired but haven't removed by the app explicitly
  useEffect(() => {
    getWithExpiry(PAYPAL_PAYMENT_IN_PROGRESS);
  }, [appUser?.user_id]);

  // TODO(olha): Temporary disable redirect, includes a bug
  // useEffect(() => {
  //   if (isSubscribedTier || isEnterpriseTier) {
  //     localStorage.removeItem(REDIRECT_TO_STRIPE_TIMESTAMP);
  //     return;
  //   }

  //   const redirectTimestamp = localStorage.getItem(
  //     REDIRECT_TO_STRIPE_TIMESTAMP,
  //   );

  //   const shouldRedirectToStripe =
  //     dayjs().valueOf() - Number(redirectTimestamp) <=
  //     REDIRECT_TO_STRIPE_EXP_TIME;

  //   if (shouldRedirectToStripe) {
  //     navigate(AppRoutes.PAYMENT);
  //     localStorage.removeItem(REDIRECT_TO_STRIPE_TIMESTAMP);
  //     return;
  //   } else {
  //     localStorage.removeItem(REDIRECT_TO_STRIPE_TIMESTAMP);
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isSubscribedTier, isEnterpriseTier, appUser.tier_id, dispatch]);

  useEffect(() => {
    if (
      appUser?.status !== UserStatus.MAINTENANCE &&
      appUser?.metadata?.user_master_status !== UserStatus.MAINTENANCE
    ) {
      setMaintenanceMode(false);
    } else {
      setMaintenanceMode(true);
    }
  }, [appUser?.metadata?.user_master_status, appUser?.status]);

  const routes = useMemo(() => {
    return isEnterpriseTier
      ? defaultRoutes.filter((route) =>
          ENTERPRISE_PRIVATE_ROUTES.includes(route.path),
        )
      : defaultRoutes;
  }, [isEnterpriseTier]);

  const handleCloseAccessModal = () => {
    updateIsAccessModalShown(true);
  };

  if (maintenanceMode) {
    return <MaintenanceScreen />;
  }

  if (appUser?.status === UserStatus.QUEUE) {
    return <QueueScreen />;
  }

  return (
    <>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element}>
            {route.children &&
              (isEnterpriseTier
                ? route.children.filter(
                    (item) =>
                      !item.path ||
                      ENTERPRISE_MANAGE_ACCOUNT_ROUTES.includes(item.path),
                  )
                : route.children
              ).map((child, childIndex) => (
                <Route
                  key={childIndex}
                  path={child.path}
                  element={child.element}
                />
              ))}
          </Route>
        ))}
        <Route
          path={AppRoutes.WILDCARD}
          element={<Navigate to={AppRoutes.HOME} replace />}
        />
      </Routes>

      <AccessModal isOpen={isShowAccess} onClose={handleCloseAccessModal} />
      <UpdatesModal isOpen={isShowUpdatesModal} />
      <UpdatePlanModal />
      <ContactNinjaModal />
      <CancelPaypalSubscriptionModal />
    </>
  );
};
