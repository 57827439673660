import { useMemo } from 'react';
import { Icon as PhosphorIconType } from '@phosphor-icons/react';
import { useSession } from 'src/hooks';
import { ChitChatCard, ExternalModelTabContent, TaskSkill } from 'src/types';
import { prepareExternalModelsTabContent } from 'src/utils';
import { SVG_SIZE_M } from 'src/constants';
import { Tabs, TabList, TabPanel, Tab } from 'src/v2/commonComponents/Tabs';
import styles from './ThreadChitChatCard.module.scss';

// TODO(olha): needs refactoring
import { ThreadMessageContent } from 'src/components/FlatAppearance/components/ThreadMessageContent';
import { UpgradeButton } from 'src/components/UpgradeButton';
import { Icon } from 'src/components/Icon';
import { ProgressBar } from 'src/components/FlatAppearance/components/ThreadCombinedCard/components/ProgressBar';

interface TabData extends ExternalModelTabContent {
  IconComponent?: PhosphorIconType;
}

enum ChitChatTabs {
  SEARCH_RESULT = 'search_result',
}

interface ThreadChatCardProps {
  chitChatCard: ChitChatCard;
  isStreaming?: boolean;
}

const TEXT_MAX_SIZE = 150;

export const ThreadChitChatCard = ({
  chitChatCard,
  isStreaming,
}: ThreadChatCardProps) => {
  const { isOpenTier, isFreeTier, isStandardTier } = useSession();
  const { data } = chitChatCard;
  const { ninja_model_output, referenced_model_summary, skill } = data || {};

  const { references: external_models_result } = referenced_model_summary || {};

  const tabsData: TabData[] = useMemo(
    () => [
      ...(ninja_model_output
        ? [
            {
              tabName: ChitChatTabs.SEARCH_RESULT,
              iconName: 'ninjaOutlined',
              tabTitle: 'Ninja',
              content: ninja_model_output || '',
            },
          ]
        : []),
      ...(external_models_result
        ? prepareExternalModelsTabContent(external_models_result).map(
            ({ tabName, tabTitle, iconName, content }) => ({
              tabName,
              iconName,
              tabTitle,
              content,
            }),
          )
        : []),
    ],
    [ninja_model_output, external_models_result],
  );

  if (
    skill === TaskSkill.DOCUMENT_QA &&
    (isFreeTier || isStandardTier || isOpenTier)
  ) {
    if (ninja_model_output) {
      const trimmedContent = ninja_model_output.replace(/^\n+|\n+$/g, '');
      const isLongText = trimmedContent.length > TEXT_MAX_SIZE;
      const slicedContent = `${trimmedContent.slice(0, TEXT_MAX_SIZE).trim()}...`;
      return (
        <div>
          <div className={styles.contentWrapper}>
            <ThreadMessageContent
              content={slicedContent}
              isStreaming={isStreaming}
            />
            {isLongText && <div className={styles.blurOverlay} />}
          </div>
          {isLongText && (
            <div className={styles.upsellBlockWrapper}>
              <span className={styles.upsellBlockText}>
                Upgrade to Pro to reveal the answer and include your file(s) in
                follow-up conversations.
              </span>
              <UpgradeButton dataGTM="File-agent-response-upgrade" />
            </div>
          )}
        </div>
      );
    }
  }

  return (
    <div className={styles.root}>
      <Tabs<string> defaultActiveTab={ChitChatTabs.SEARCH_RESULT}>
        {/* TODO(olha): create a common component for ThreadResearchCard & ThreadChitChatCard */}
        <TabList>
          {tabsData.map(({ tabName, iconName, IconComponent, tabTitle }) => (
            <Tab key={tabName} tab={tabName} className={styles.tab}>
              {iconName ? (
                <Icon type={iconName} size={SVG_SIZE_M} />
              ) : IconComponent ? (
                <IconComponent size={SVG_SIZE_M} />
              ) : null}

              <span>{tabTitle}</span>
            </Tab>
          ))}
        </TabList>

        {tabsData.map(({ tabName, content }) => (
          <TabPanel key={tabName} tab={tabName}>
            {content.length === 0 ? (
              <ProgressBar count={3} />
            ) : (
              <ThreadMessageContent
                content={content}
                isStreaming={isStreaming}
              />
            )}
          </TabPanel>
        ))}
      </Tabs>
    </div>
  );
};
