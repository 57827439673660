import { UserGear } from '@phosphor-icons/react';
import { Message } from 'src/types';
import { useRef } from 'react';
import { useThreads } from 'src/hooks';
import { SVG_SIZE_M } from 'src/constants';
import classNames from 'classnames';
import { useCollapse } from 'react-collapsed';

type GetToggleProps = ReturnType<typeof useCollapse>['getToggleProps'];

type RedoWithPersonaProps = {
  message: Message;
  isShowRedoWithPersona?: boolean;
  onToggleRedoWithPersona: GetToggleProps;
  onCloseRedoSkillActions: () => void;
  onCloseNegativeFeedbackForm: () => void;
};

export const RedoWithPersona = ({
  message,
  isShowRedoWithPersona = false,
  onToggleRedoWithPersona,
  onCloseRedoSkillActions,
  onCloseNegativeFeedbackForm,
}: RedoWithPersonaProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { isSubmitHappened } = useThreads();
  const userInput = message?.original_query || '';

  const messageIdValidPart = message.message_id?.slice(-6);

  function toggleActions() {
    onCloseRedoSkillActions();
    onCloseNegativeFeedbackForm();
  }

  return (
    <div {...onToggleRedoWithPersona()}>
      <div
        className={classNames('nj-message-footer--redo-with-persona', {
          selected: isShowRedoWithPersona,
        })}
        ref={containerRef}
      >
        <button
          onClick={toggleActions}
          id={`redo-with-persona-${messageIdValidPart}`}
          disabled={isSubmitHappened || !userInput}
          title="Regenerate with different tone & verbosity"
        >
          <UserGear
            size={SVG_SIZE_M}
            weight={isShowRedoWithPersona ? 'fill' : 'regular'}
            color="currentColor"
          />
          Try different persona
        </button>
      </div>
    </div>
  );
};
