import { Header } from 'src/pages/AuthPages/components/Header';
import atlasSmile from 'src/images/login/atlas_smile.png';
import {
  DEFAULT_PRIVACY_POLICY_LINK,
  DEFAULT_TERMS_OF_SERVICE_LINK,
} from 'src/constants/externalLinks';
import { FormProvider } from 'src/components/FormProvider';
import { useForm } from 'react-hook-form';
import { FormInput } from 'src/components/FormInput';
import { ReactNode, useState } from 'react';
import { signUp } from 'aws-amplify/auth';
import { ServiceError } from '@aws-amplify/core/src/types/errors';
import { v4 as uuid_v4 } from 'uuid';
import { SignupHeader } from 'src/pages/AuthPages/components/SignupHeader';
import { PasswordHint } from 'src/pages/AuthPages/components/PasswordHint';
import { useBreakpoint, useQueryParams } from 'src/hooks';
import { Link, useNavigate } from 'react-router-dom';
import { ErrorMessageBlock } from 'src/pages/AuthPages/components/ErrorMessageBlock';
import { sendGTMEvent } from 'src/utils';
import { AppRoutes, GTMEvent } from 'src/types';
import { EmailVerificationBlock } from 'src/pages/AuthPages/components/EmailVerificationBlock';
import { COGNITO_PRE_SIGNUP_ERROR_PREFIX } from 'src/constants/cognito';
import {
  USER_NAME_MAX_LENGTH,
  USER_NAME_MIN_LENGTH,
  USER_NAME_PATTERN_1,
  USER_NAME_PATTERN_2,
} from 'src/constants';

enum SignUpField {
  FULL_NAME = 'full_name',
  EMAIL = 'email',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirm_password',
}

interface FormData {
  [SignUpField.FULL_NAME]: string;
  [SignUpField.EMAIL]: string;
  [SignUpField.PASSWORD]: string;
  [SignUpField.CONFIRM_PASSWORD]: string;
}

export const SignupWithEmailPage = () => {
  const [errorMessage, setErrorMessage] = useState<string | ReactNode | null>(
    null,
  );
  const [errorFields, setErrorFields] = useState<SignUpField[]>([]);
  const { isMobileOrTablet } = useBreakpoint();
  const { searchParams } = useQueryParams();
  const navigate = useNavigate();
  const defaultEmail = searchParams.email;

  const methods = useForm<FormData>({
    defaultValues: {
      [SignUpField.FULL_NAME]: '',
      [SignUpField.EMAIL]: defaultEmail || '',
      [SignUpField.PASSWORD]: '',
      [SignUpField.CONFIRM_PASSWORD]: '',
    },
  });

  const { handleSubmit, reset } = methods;

  const submitForm = async (data: FormData) => {
    try {
      const firstName =
        data[SignUpField.FULL_NAME]?.split(' ')[0] ||
        data[SignUpField.FULL_NAME];
      const lastName =
        data[SignUpField.FULL_NAME].substring(firstName.length + 1) || '';

      if (
        !USER_NAME_PATTERN_1.value.test(firstName) ||
        (lastName !== '' && !USER_NAME_PATTERN_1.value.test(lastName))
      ) {
        setErrorMessage(USER_NAME_PATTERN_1.message);
        setErrorFields([SignUpField.FULL_NAME]);
        return;
      } else if (
        USER_NAME_PATTERN_2.value.test(firstName) ||
        (lastName !== '' && USER_NAME_PATTERN_2.value.test(lastName))
      ) {
        setErrorMessage(USER_NAME_PATTERN_2.message);
        setErrorFields([SignUpField.FULL_NAME]);
        return;
      }

      if (firstName.length < USER_NAME_MIN_LENGTH) {
        setErrorMessage(`The name is too short`);
        setErrorFields([SignUpField.FULL_NAME]);
        return;
      }
      if (
        firstName.length > USER_NAME_MAX_LENGTH ||
        lastName.length > USER_NAME_MAX_LENGTH
      ) {
        setErrorMessage(`The name is too long`);
        setErrorFields([SignUpField.FULL_NAME]);
        return;
      }

      if (data[SignUpField.PASSWORD] !== data[SignUpField.CONFIRM_PASSWORD]) {
        setErrorMessage('Passwords do not match. Please try again.');
        setErrorFields([SignUpField.PASSWORD, SignUpField.CONFIRM_PASSWORD]);
        return;
      }
      setErrorMessage('');
      setErrorFields([]);
      const userName = `Plain_${uuid_v4()}`;
      await signUp({
        username: userName,
        password: data[SignUpField.PASSWORD],
        options: {
          userAttributes: {
            email: data[SignUpField.EMAIL].toLowerCase(),
            given_name: firstName,
            family_name: lastName,
          },
        },
      });
      sendGTMEvent(GTMEvent.EMAIL_SIGNUP_SUCCESS);

      reset();
      navigate(
        `/verified?email=${encodeURIComponent(data[SignUpField.EMAIL].toLowerCase())}`,
        { replace: true },
      );
    } catch (error) {
      sendGTMEvent(GTMEvent.EMAIL_SIGNUP_FAILURE);

      const errorMessage = error ? (error as ServiceError).message : 'error';

      switch ((error as ServiceError).name) {
        case 'InvalidPasswordException':
          setErrorMessage(
            <span>
              Password does not meet required criteria. Please{' '}
              <PasswordHint text="Review" /> and try again.
            </span>,
          );
          setErrorFields([SignUpField.PASSWORD, SignUpField.CONFIRM_PASSWORD]);
          return;
        case 'NotAuthorizedException':
        case 'UsernameExistsException':
          setErrorMessage(errorMessage);
          setErrorFields([SignUpField.EMAIL]);
          return;
        case 'UserLambdaValidationException':
          if (errorMessage.includes('Your email has not been verified yet')) {
            setErrorMessage(
              <EmailVerificationBlock
                email={data[SignUpField.EMAIL].toLowerCase()}
              />,
            );
          } else if (
            errorMessage.includes('You already signed up with this email.')
          ) {
            setErrorMessage(
              <span>
                The Ninja account already exists. Head back to{' '}
                <Link to={AppRoutes.LOGIN}>log in.</Link>
              </span>,
            );
          } else if (errorMessage.includes("You've already signed up via")) {
            setErrorMessage(
              <span>
                {errorMessage.replace(COGNITO_PRE_SIGNUP_ERROR_PREFIX, '')}{' '}
                <Link to={AppRoutes.LOGIN}>log in</Link>
              </span>,
            );
          } else {
            setErrorMessage(errorMessage);
          }
          setErrorFields([SignUpField.EMAIL]);
          return;
        default:
          setErrorMessage(error ? (error as ServiceError).message : 'error');
          setErrorFields([]);
          return;
      }
    }
  };

  return (
    <div className="nj-auth-layout nj-beta">
      <Header rightSide={<SignupHeader />} />
      <main className="nj-auth-content">
        <h1 className="nj-auth-header-blue-gradient">Meet Ninja</h1>
        <h2 className="nj-auth-header-small">Your Autonomous AI Agent</h2>
        <h3 className="nj-auth-header-secondary">Set up your account</h3>
        <>
          <FormProvider<FormData> methods={methods}>
            <form
              onSubmit={handleSubmit(submitForm)}
              className="nj-auth-form"
              autoComplete="off"
            >
              <div className="nj-auth-form--field">
                <legend className="nj-auth-form--legend">Email</legend>
                <FormInput
                  name={SignUpField.EMAIL}
                  type="email"
                  className="nj-auth-form--input"
                  placeholder="Enter Email"
                  required={true}
                  hasErrorBorder={
                    !!errorMessage && errorFields.includes(SignUpField.EMAIL)
                  }
                />
              </div>
              <div className="nj-auth-form--field">
                <legend className="nj-auth-form--legend">Name</legend>
                <FormInput
                  name={SignUpField.FULL_NAME}
                  type="text"
                  className="nj-auth-form--input"
                  placeholder="Enter First & Last Name"
                  required={true}
                  hasErrorBorder={
                    !!errorMessage &&
                    errorFields.includes(SignUpField.FULL_NAME)
                  }
                />
              </div>
              <div className="nj-auth-form--field">
                <legend className="nj-auth-form--legend">
                  Password <PasswordHint />
                </legend>
                <FormInput
                  name={SignUpField.PASSWORD}
                  type="password"
                  className="nj-auth-form--input"
                  placeholder="Create Password"
                  required={true}
                  hasErrorBorder={
                    !!errorMessage && errorFields.includes(SignUpField.PASSWORD)
                  }
                />
              </div>
              <div className="nj-auth-form--field">
                <legend className="nj-auth-form--legend">
                  Confirm password
                </legend>
                <FormInput
                  name={SignUpField.CONFIRM_PASSWORD}
                  type="password"
                  className="nj-auth-form--input"
                  placeholder="Confirm Password"
                  required={true}
                  hasErrorBorder={
                    !!errorMessage &&
                    errorFields.includes(SignUpField.CONFIRM_PASSWORD)
                  }
                />
              </div>
              <button className="nj-auth-form--submit-button" type="submit">
                Sign up
              </button>
              {!!errorMessage && (
                <ErrorMessageBlock errorMessage={errorMessage} />
              )}
            </form>
          </FormProvider>
          <p className="nj-auth-agreement-label">
            By signing up, you agree to the
            <a
              href={DEFAULT_TERMS_OF_SERVICE_LINK}
              target="_blank"
              rel="noreferrer"
              className="nj-auth-terms-link"
            >
              &nbsp;Terms of Service&nbsp;
            </a>
            and have read and acknowledge our
            <a
              href={DEFAULT_PRIVACY_POLICY_LINK}
              target="_blank"
              rel="noreferrer"
              className="nj-auth-terms-link"
            >
              &nbsp;Privacy Policy
            </a>
          </p>
        </>
      </main>
      {!isMobileOrTablet && (
        <img
          className="nj-auth-background-image"
          src={atlasSmile}
          alt="Ninja"
        />
      )}
    </div>
  );
};
