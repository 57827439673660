import styles from './VerificationPage.module.scss';
import { Header } from 'src/v2/pages/auth/components/Header';
import { Footer } from 'src/v2/pages/auth/components/Footer';
import { useRandomSignupBackgroundStyle } from 'src/hooks/useRandomSignupBackgroundStyle';
import { isEnterprise } from 'src/utils';
import {
  EnterpriseMeetNinjaSection,
  MeetNinjaSection,
} from 'src/v2/pages/auth/components/MeetNinjaSection';
import { EmailVerificationBlock } from 'src/v2/pages/auth/components/EmailVerificationBlock';
import { useQueryParams } from 'src/hooks';

export const VerificationPage = () => {
  const { backgroundImageStyle } = useRandomSignupBackgroundStyle();
  const { searchParams } = useQueryParams();

  const { email } = searchParams;

  return (
    <div className={styles.root} style={backgroundImageStyle}>
      <Header isSignUp />
      <main className={styles.container}>
        {isEnterprise ? (
          <>
            <EnterpriseMeetNinjaSection />
            <div className={styles.mainSection}>
              <EmailVerificationBlock email={email} />
            </div>
          </>
        ) : (
          <>
            <MeetNinjaSection isSignup={false} />
            <div className={styles.mainSection}>
              <EmailVerificationBlock email={email} />
            </div>
          </>
        )}
      </main>
      {!isEnterprise && <Footer />}
    </div>
  );
};
