import { Modal } from 'src/components/Modal';
import {
  SUBSCRIPTION_PENDING_CHANGES_NOTIFICATION_MESSAGE,
  SVG_SIZE_L,
} from 'src/constants';
import { X } from '@phosphor-icons/react';
import { useBreakpoint, useSubscriptionInfo } from 'src/hooks';
import { useGetUpsellModalsConfigQuery } from 'src/store/services';
import { PlanFeatureItem } from '../PlanCardModal/components/PlanFeatureItem';
import {
  WELCOME_UPSELL_MODAL_FREE_FEATURES,
  WELCOME_UPSELL_MODAL_STANDARD_FEATURES,
  WELCOME_UPSELL_MODAL_PRO_FEATURES,
  WELCOME_UPSELL_MODAL_ULTRA_FEATURES,
  EXTERNAL_MODELS_PRO_LIST,
  EXTERNAL_MODELS_ULTRA_LIST,
  EXTERNAL_MODELS_STANDARD_LIST,
  EXTERNAL_MODELS_FREE_LIST,
} from './constants';
import { AppRoutes, WelcomeUpsellModalType } from 'src/types';
import styles from './WelcomeUpsellModal.module.scss';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { AWSResizableLogo } from 'src/images/logos/aws-resizable-logo';
import { ForbesLogo } from 'src/images/logos/forbes-logo';
import { WSJLogo } from 'src/images/logos/wsj-logo';
import { StreetLogo } from 'src/images/logos/street-logo';
import { VentureBeatLogo } from 'src/images/logos/venture-beat-logo';
import { toast } from 'react-toastify';
import { ABTesting_WelcomeSignupShown } from '../ABTestingFramework';

interface WelcomeUpsellModalProps {
  modalType: WelcomeUpsellModalType;
  isOpen: boolean;
  onClose: () => void;
  onContinue?: () => void;
  onUpgrade?: () => void;
}

export const WelcomeUpsellModal = ({
  modalType,
  isOpen,
  onClose,
  onContinue,
  onUpgrade,
}: WelcomeUpsellModalProps) => {
  const { isMobile } = useBreakpoint();
  const navigate = useNavigate();
  const { subscriptionInfo } = useSubscriptionInfo();

  const isEnterpriseModal = modalType === 'enterprise-tier-welcome-modal';

  const isSubscribedModal =
    modalType === 'ultra-welcome-modal' ||
    modalType === 'pro-welcome-modal' ||
    modalType === 'standard-welcome-modal';

  const isStandardModal = modalType === 'standard-welcome-modal';
  const isProModal = modalType === 'pro-welcome-modal';
  const isUltraModal =
    modalType === 'ultra-welcome-modal' ||
    modalType === 'enterprise-tier-welcome-modal';

  const isFreeModal = modalType === 'free-tier-welcome-modal';
  const isOpenModal = modalType === 'open-tier-welcome-modal';

  const { data } = useGetUpsellModalsConfigQuery();

  const welcomeUpsellData = (data || {})?.[modalType];

  useEffect(() => {
    ABTesting_WelcomeSignupShown(isOpen);
  }, [isOpen]);

  const features = useMemo(() => {
    if (isUltraModal) {
      return WELCOME_UPSELL_MODAL_ULTRA_FEATURES;
    }
    if (isProModal) {
      return WELCOME_UPSELL_MODAL_PRO_FEATURES;
    }
    if (isStandardModal) {
      return WELCOME_UPSELL_MODAL_STANDARD_FEATURES;
    }
    if (isFreeModal) {
      return WELCOME_UPSELL_MODAL_FREE_FEATURES;
    }
    return WELCOME_UPSELL_MODAL_FREE_FEATURES;
  }, [isUltraModal, isProModal, isStandardModal, isFreeModal]);

  const models = useMemo(() => {
    if (isUltraModal) {
      return EXTERNAL_MODELS_ULTRA_LIST;
    }
    if (isProModal) {
      return EXTERNAL_MODELS_PRO_LIST;
    }

    if (isStandardModal) {
      return EXTERNAL_MODELS_STANDARD_LIST;
    }

    return EXTERNAL_MODELS_FREE_LIST;
  }, [isUltraModal, isProModal, isStandardModal]);

  const standardMoreModels = [
    'OpenAI GPT-4o',
    'Anthropic Claude 3.5',
    'Google Gemini Pro',
    'and many more',
  ];

  if (!welcomeUpsellData) {
    return null;
  }

  const {
    title,
    description,
    continueButtonTitle,
    upgradeButtonTitle,
    upgradeButtonId,
    isContinueButtonBlue,
    isUpgradeButtonBlue,
    reverseButtonsOrder,
  } = welcomeUpsellData;

  const handleContinueClick = () => {
    onClose();

    if (onContinue) {
      onContinue();
    }
  };

  const handleUpgradeClick = async () => {
    if (isOpenModal) {
      navigate(AppRoutes.SIGN_UP);
    } else {
      if (
        !!subscriptionInfo?.is_cancelled ||
        !!subscriptionInfo?.has_scheduled_downgrade ||
        !!subscriptionInfo?.has_scheduled_plan_change ||
        !!subscriptionInfo?.processing_plan_change
      ) {
        toast.error(SUBSCRIPTION_PENDING_CHANGES_NOTIFICATION_MESSAGE);
      } else {
        navigate(AppRoutes.PAYMENT);
      }
    }
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className={classNames(styles.modal, {
        [styles.open]: isOpenModal,
        [styles.free]: isFreeModal,
        [styles.standard]: isStandardModal,
        [styles.pro]: isProModal,
        [styles.ultra]: isUltraModal,
      })}
      overlayClassName={styles.overlay}
      showCloseIcon={false}
      blockScroll={false}
    >
      <div className={styles.root}>
        <button type="button" className={styles.buttonClose} onClick={onClose}>
          <X size={SVG_SIZE_L} />
        </button>

        <div className={styles.main}>
          <div className={styles.titleWrapper}>
            <h2 className={styles.title}>{title}</h2>

            {description && <h4 className={styles.subtitle}>{description}</h4>}
          </div>

          <div className={styles.featuresBlock}>
            <div className={styles.featuresWrapper}>
              <ul className={styles.leftSideFeatures}>
                {isFreeModal || isOpenModal ? (
                  <div className={styles.subTitleWrapper}>
                    <p className={styles.subTitle}>Basic</p>
                    <p className={styles.caption}>
                      Try Ninja for free, no credit card required
                    </p>
                  </div>
                ) : (
                  <p className={styles.subTitle}>Features</p>
                )}

                {features.map(({ title }) => (
                  <PlanFeatureItem title={title} key={title} />
                ))}
              </ul>

              <ul className={styles.rightSideFeatures}>
                {isFreeModal || isOpenModal ? (
                  <div>
                    <p className={styles.subTitle}>
                      Plans starting at <span className="colored">$5/mo</span>
                    </p>
                    <p className={styles.caption}>
                      Access the best AI features for enhanced productivity
                    </p>
                  </div>
                ) : (
                  <p className={styles.subTitle}>AI Models</p>
                )}

                {models.map((item, index) => (
                  <li className={styles.listItem} key={index}>
                    {item.icon}
                    <span className={styles.text}>{item.title}</span>
                  </li>
                ))}

                {isUltraModal && (
                  <li className={styles.moreModelsText}>16+ more models</li>
                )}

                {(isFreeModal || isOpenModal) && (
                  <ul className={styles.discList}>
                    {standardMoreModels.map((item) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                )}
              </ul>
            </div>
          </div>
        </div>

        {/* TODO(olha): create a common component for reuse */}
        <div className={styles.referenceBlock}>
          <p className={styles.referenceTitle}>As seen on</p>
          <div className={styles.logosWrapper}>
            <AWSResizableLogo
              width={isMobile ? 23 : 35}
              height={isMobile ? 15 : 20}
            />

            <ForbesLogo
              width={isMobile ? 57 : 75}
              height={isMobile ? 15 : 20}
            />

            <WSJLogo width={isMobile ? 27 : 39} height={isMobile ? 15 : 20} />

            <StreetLogo
              width={isMobile ? 60 : 70}
              height={isMobile ? 18 : 20}
            />

            <VentureBeatLogo
              width={isMobile ? 79 : 126}
              height={isMobile ? 11 : 16}
            />
          </div>
        </div>

        <div className={styles.footerWrapper}>
          <div
            className={classNames(styles.footer, {
              [styles.reverseOrder]: reverseButtonsOrder,
            })}
          >
            {/* {isEnterpriseModal && (
              <a
                href={DEFAULT_NINJATECH_LINK}
                target="_blank"
                rel="noreferrer"
                className={styles.learnMoreLink}
              >
                <>
                  <ArrowSquareOut size={SVG_SIZE_M} />
                  <span>Learn more</span>
                </>
              </a>
            )} */}

            <button
              type="button"
              className={classNames(styles.button, {
                [styles.active]: isContinueButtonBlue,
                [styles.center]: isEnterpriseModal || isSubscribedModal,
              })}
              onClick={handleContinueClick}
            >
              {continueButtonTitle}
            </button>

            {!isEnterpriseModal && !isSubscribedModal && (
              <button
                id={upgradeButtonId}
                type="button"
                className={classNames(styles.button, {
                  [styles.active]: isUpgradeButtonBlue,
                })}
                onClick={onUpgrade ?? handleUpgradeClick}
              >
                {upgradeButtonTitle}
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
