import {
  PaymentPlanOption,
  PaymentPlanOptionWithSale,
  SubscriptionOption,
} from 'src/types';
import {
  getCalculatedSale,
  getNewAmountWithDiscount,
} from 'src/utils/payments';
import { SVG_SIZE_M } from 'src/constants/sizes';
import { Icon } from 'src/components/Icon';
import { ChatGPTLogo } from 'src/images/logos/chat-gpt';
import { AnthropicLogo } from 'src/images/logos/anthropic-logo';
import { NinjaAgentsOutlinedIcon } from 'src/images/icons/ninjaAgentsOutlined';
import { AWSLogo } from 'src/images/logos/aws-logo';
import { LEARN_MORE_ADVANCED_CODING } from './externalLinks';

export const BASE_PRICE_PER_MONTH = 10;
export const BASE_PRICE_PER_YEAR = 120;
export const SALE_PER_FIRST_MONTH = 50;
export const SALE_PER_FIRST_YEAR = 30;
export const FREE_CREDITS = 10;
export const PROMO_COUPON_AMOUNT = 5;
export const PROMO_COUPON = 'NINJA5';
export const PRICE_FOR_ADD_ON = 5;

export const PAYMENT_DATADOG_ERROR = 'Payment failed';
export const PAYMENT_ERROR_MESSAGE =
  'We’re having trouble processing your payment. Please check your information or try a different payment method.';

export const PRICE_PER_MONTH = BASE_PRICE_PER_MONTH - PROMO_COUPON_AMOUNT;
export const PRICE_PER_YEAR =
  (BASE_PRICE_PER_YEAR * (100 - SALE_PER_FIRST_YEAR)) / 100;

export const DISCOUNT_PER_MONTH =
  (SALE_PER_FIRST_MONTH / 100) * BASE_PRICE_PER_MONTH;
export const DISCOUNT_PER_YEAR =
  (SALE_PER_FIRST_YEAR / 100) * BASE_PRICE_PER_YEAR;

// TODO(olha): use BE data, remove hardcode
export const PLAN_OPTIONS: PaymentPlanOptionWithSale[] = [
  {
    lookup_key: 'pro_monthly_10',
    amount: 1000,
    isDefault: true,
    newAmount: getNewAmountWithDiscount(1000, PROMO_COUPON_AMOUNT),
    promotion_code: PROMO_COUPON,
    sale: getCalculatedSale(1000, PROMO_COUPON_AMOUNT),
    couponAmount: PROMO_COUPON_AMOUNT,
  },
  {
    lookup_key: 'pro_monthly_20',
    amount: 2000,
    newAmount: getNewAmountWithDiscount(2000, PROMO_COUPON_AMOUNT),
    promotion_code: PROMO_COUPON,
    sale: getCalculatedSale(2000, PROMO_COUPON_AMOUNT),
    couponAmount: PROMO_COUPON_AMOUNT,
  },
  {
    lookup_key: 'pro_monthly_30',
    amount: 3000,
    newAmount: getNewAmountWithDiscount(3000, PROMO_COUPON_AMOUNT),
    promotion_code: PROMO_COUPON,
    sale: getCalculatedSale(3000, PROMO_COUPON_AMOUNT),
    couponAmount: PROMO_COUPON_AMOUNT,
  },
];

export const PLAN_OPTIONS_FOR_FREE: PaymentPlanOption[] = [
  {
    lookup_key: 'pro_monthly_10',
    amount: 1000,
    isDefault: true,
  },
  {
    lookup_key: 'pro_monthly_20',
    amount: 2000,
  },
  {
    lookup_key: 'pro_monthly_30',
    amount: 3000,
  },
];

export const RENEW_EARLY_MONTHLY_OPTIONS: PaymentPlanOption[] = [
  {
    lookup_key: 'pro_monthly_10',
    amount: 1000,
    tasksCount: 600,
  },
  {
    lookup_key: 'pro_monthly_20',
    amount: 2000,
    tasksCount: 1200,
  },
  {
    lookup_key: 'pro_monthly_30',
    amount: 3000,
    tasksCount: 1800,
  },
];

export const RENEW_EARLY_ANNUALLY_OPTIONS: PaymentPlanOption[] = [
  {
    lookup_key: 'pro_annually_84',
    amount: 8400,
    tasksCount: 24000,
    isAnnual: true,
  },
  {
    lookup_key: 'pro_annually_168',
    amount: 16800,
    tasksCount: 48000,
    isAnnual: true,
  },
  {
    lookup_key: 'pro_annually_252',
    amount: 25200,
    tasksCount: 72000,
    isAnnual: true,
  },
];

export const PLAN_FREE_FEATURES = [
  { title: 'Writer', withBorder: false },
  { title: 'Researcher', withBorder: false },
  { title: 'Coder', withBorder: false },
  { title: 'Deep Researcher', withBorder: false },
  { title: 'Image Generator', withBorder: false },
  { title: 'Scheduler', withBorder: true },
  { title: '5 minute video chat sessions', withBorder: false },
  { title: '10 custom conversation tones', withBorder: false },
  { title: 'Email & In-product support', withBorder: false },
];

// TODO(olha): needs clearing and refactoring
export const PRO_MAIN_FEATURES = [
  { title: 'Researcher' },
  { title: 'Coder' },
  { title: 'Writing Advisor' },
  { title: 'Image Generator & Editor' },
  { title: 'Meeting Scheduler' },
  { title: '30 minute avatar video sessions' },
  { title: '10 custom conversation tones' },
  { title: 'Email & In-product support' },
];

export const PRO_ADDITIONAL_FEATURES = [
  { title: '30 minute avatar video sessions' },
  { title: '10 custom conversation tones' },
  { title: 'Email & In-product support' },
];

export const PLAN_PRO_FEATURES = [
  { title: 'Writer', withBorder: false },
  { title: 'Researcher', withBorder: false },
  { title: 'Coder', withBorder: false },
  { title: 'Deep Researcher', withBorder: false },
  { title: 'Image Generator', withBorder: false },
  { title: 'Image Editor', withBorder: false },
  { title: 'Multi-party Scheduler', withBorder: false },
  {
    title: '24+ External models',
    withBorder: true,
    isExternalModels: true,
  },
  {
    title: 'State of art interactive avatars',
    withBorder: false,
    // link: { label: '20 Avatars', href: PAY_PER_USE },
  },
  { title: '10 custom conversation tones', withBorder: false },
  { title: 'Email & In-product support', withBorder: false },
];

export const BASIC_FEATURES = [
  { title: '720p (HD) image generation' },
  { title: 'Creative and professional writing' },
  { title: 'Code generation & debugging' },
  { title: 'Real-time research' },
  { title: 'Individual meeting scheduling' },
];

export const PAYMENTS_TIME_FORMAT = 'h:mm A';
export const PAYMENTS_DATE_FORMAT = 'MMM D, YYYY';
export const PAYMENTS_DATE_TIME_FORMAT = 'MMM D, YYYY h:mm A';

export const REDIRECT_TO_STRIPE_EXP_TIME = 30 * 60 * 1000; // 30m

export const PAYPAL_PAYMENT_IN_PROGRESS_MESSAGE =
  'Your subscription is being updated...';

export const PAYPAL_PAYMENT_IN_PROGRESS_BANNER_TTL_SECONDS = 300;

/**
 * @deprecated
 */
export const SUBSCRIPTIONS: SubscriptionOption[] = [
  {
    plan: 'Pro',
    monthly_key: 'pro_monthly_15',
    yearly_key: 'pro_annually_126',
    originalPrice: 15,
    monthlyPrice: 10,
    yearlyPrice: 11,
    promotion_code: 'NINJAPRO5',
    firstMonthSale: 5,
  },
  {
    plan: 'Ultra',
    monthly_key: 'ultra_monthly_30',
    yearly_key: 'ultra_annually_252',
    originalPrice: 30,
    monthlyPrice: 20,
    yearlyPrice: 21,
    promotion_code: 'NINJAULTRA10',
    firstMonthSale: 10,
  },
];

export const STANDARD_FEATURES = [
  {
    id: '1',
    title: <span>720p (HD) image generation</span>,
  },
  {
    id: '2',
    title: <span>2 image aspect ratios</span>,
  },
  {
    id: '3',
    title: <span>Creative and professional writing</span>,
  },
  {
    id: '4',
    title: <span>Code generation and debugging</span>,
  },
  {
    id: '5',
    title: <span>Online research</span>,
  },
];

export const PRO_FEATURES = [
  {
    id: '1',
    title: <span>1080p (Full HD) image generation</span>,
  },
  {
    id: '2',
    title: <span>11 image aspect ratios</span>,
  },
  {
    id: '3',
    title: <span>Advanced online research</span>,
  },
  {
    id: '4',
    title: <span>Multi-party meeting scheduling</span>,
  },
  {
    id: '5',
    title: (
      <span>File upload analysis up to ~150k words across 35+ file types</span>
    ),
  },
];

export const ULTRA_FEATURES = [
  {
    id: '1',
    title: <span>2k (Quad HD) image generation</span>,
  },
  {
    id: '2',
    title: (
      <span>
        Advanced AI image editing{' '}
        <span className="coming-soon">(coming soon)</span>
      </span>
    ),
  },
  {
    id: '3',
    title: (
      <div className="space-between">
        <span>
          Advanced coding
          <span className="coming-soon"> (coming soon)</span>
          <a
            href={LEARN_MORE_ADVANCED_CODING}
            target="_blank"
            rel="noreferrer"
            className="nj-plan-section--highlight-link"
          >
            learn more
          </a>
        </span>
      </div>
    ),
  },
  {
    id: '4',
    title: (
      <span>File upload analysis up to ~750k words across 35+ file types</span>
    ),
  },
];

export const STANDARD_MODELS = [
  {
    id: '1',
    icon: <NinjaAgentsOutlinedIcon width={SVG_SIZE_M} height={SVG_SIZE_M} />,
    text: <span>Fine-tuned Llama 3.1 405b</span>,
    isShowImageIcon: false,
  },
  {
    id: '2',
    icon: <NinjaAgentsOutlinedIcon width={SVG_SIZE_M} height={SVG_SIZE_M} />,
    text: <span>Ninja Pix (PixArt-Sigma)</span>,
    isShowImageIcon: false,
  },
  {
    id: '3',
    icon: <NinjaAgentsOutlinedIcon width={SVG_SIZE_M} height={SVG_SIZE_M} />,
    text: <span>Ninja Flux (FLUX.1)</span>,
    isShowImageIcon: false,
  },
];

export const PRO_MODELS = [
  {
    id: '1',
    icon: <NinjaAgentsOutlinedIcon width={SVG_SIZE_M} height={SVG_SIZE_M} />,
    text: <span>Fine-tuned Llama 3.1 405b</span>,
    isShowImageIcon: false,
  },
  {
    id: '2',
    icon: <NinjaAgentsOutlinedIcon width={SVG_SIZE_M} height={SVG_SIZE_M} />,
    text: <span>Ninja Pix (PixArt-Sigma)</span>,
    isShowImageIcon: false,
  },
  {
    id: '3',
    icon: <NinjaAgentsOutlinedIcon width={SVG_SIZE_M} height={SVG_SIZE_M} />,
    text: <span>Ninja Flux (FLUX.1)</span>,
    isShowImageIcon: false,
  },
  {
    id: '4',
    icon: <ChatGPTLogo size={SVG_SIZE_M} />,
    text: <span>OpenAI GPT-4o Mini</span>,
    isShowImageIcon: false,
  },
  {
    id: '5',
    icon: <AnthropicLogo size={SVG_SIZE_M} />,
    text: <span>Anthropic Claude 3.5 Sonnet</span>,
    isShowImageIcon: false,
  },
  {
    id: '6',
    icon: <Icon type="googleGeminiProLogo" size={SVG_SIZE_M} />,
    text: <span>Google Gemini 1.5 Flash</span>,
    isShowImageIcon: false,
  },
];

export const ULTRA_MODELS = [
  {
    id: '1',
    icon: <NinjaAgentsOutlinedIcon width={SVG_SIZE_M} height={SVG_SIZE_M} />,
    text: <span>Fine-tuned Llama 3.1 405b</span>,
    isShowImageIcon: false,
  },
  {
    id: '2',
    icon: <NinjaAgentsOutlinedIcon width={SVG_SIZE_M} height={SVG_SIZE_M} />,
    text: <span>Ninja Pix (PixArt-Sigma)</span>,
    isShowImageIcon: false,
  },
  {
    id: '3',
    icon: <NinjaAgentsOutlinedIcon width={SVG_SIZE_M} height={SVG_SIZE_M} />,
    text: <span>Ninja Flux (FLUX.1)</span>,
    isShowImageIcon: false,
  },
  {
    id: '4',
    icon: <Icon type="bedrockStableLogo" size={SVG_SIZE_M} />,
    text: <span>Stable Diffusion XL 1.0</span>,
    isShowImageIcon: false,
  },
  {
    id: '5',
    icon: <ChatGPTLogo size={SVG_SIZE_M} />,
    text: <span>OpenAI DALL·E 3</span>,
    isShowImageIcon: false,
  },
  {
    id: '6',
    icon: <AWSLogo size={SVG_SIZE_M} />,
    text: <span>Amazon Titan Image Generator</span>,
    isShowImageIcon: false,
  },
  {
    id: '7',
    icon: <ChatGPTLogo size={SVG_SIZE_M} />,
    text: <span>OpenAI GPT-4o</span>,
    isShowImageIcon: false,
  },
  {
    id: '8',
    icon: <Icon type="googleGeminiProLogo" size={SVG_SIZE_M} />,
    text: <span>Google Gemini 1.5 Pro</span>,
    isShowImageIcon: false,
  },
  {
    id: '9',
    icon: <Icon type="wolframAlfaLogo" size={SVG_SIZE_M} />,
    text: (
      <span>
        Wolfram Alpha <span className="coming-soon"> (coming soon)</span>
      </span>
    ),
    isShowImageIcon: false,
  },
  {
    id: '10',
    icon: <Icon type="mistralLogo" size={SVG_SIZE_M} />,
    text: <span>Mistral Large</span>,
    isShowImageIcon: false,
  },
];

export const SUBSCRIPTION_PENDING_CHANGES_NOTIFICATION_MESSAGE =
  'Unable to update plans due to pending subscription changes';
