import styles from './EnterpriseMainSignupSection.module.scss';
import { AppRoutes } from 'src/types';
import { Link } from 'react-router-dom';
import { TermsSection } from 'src/v2/pages/auth/components/TermsSection/TermsSection';
import { EnterpriseSignupForm } from 'src/v2/pages/auth/components/enterprise/EnterpriseSignupForm';

export const EnterpriseMainSignupSection = () => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div>
          <h2 className={styles.signUpHeader}>Sign up</h2>
          <div className={styles.signUpSubHeader}>Setup your account</div>
        </div>
        <EnterpriseSignupForm />
        <div className={styles.alreadySignIn}>
          <span className={styles.alreadySignInText}>
            Already have an account?
          </span>
          &nbsp;
          <Link to={AppRoutes.LOGIN} className={styles.loginLink}>
            Log in
          </Link>
        </div>

        <TermsSection />
      </div>
    </div>
  );
};
