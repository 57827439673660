export const DEFAULT_NINJA_BETA_LINK = 'https://www.ninjatech.ai/';
export const DEFAULT_PRIVACY_POLICY_LINK = 'https://www.ninjatech.ai/privacy';

export const DEFAULT_TERMS_OF_SERVICE_LINK =
  'https://www.ninjatech.ai/terms-of-service';

export const DEFAULT_ACCEPTABLE_USE_POLICY =
  'https://www.ninjatech.ai/acceptable-use-policy';

export const DEFAULT_NINJATECH_LINK = 'https://www.ninjatech.ai';
export const DEFAULT_NINJATECH_BLOG_LINK = 'https://www.ninjatech.ai/news';

export const LEARN_MORE_PRICES_LINK =
  'https://www.ninjatech.ai/pricing#compare-plans';
export const PAY_PER_USE = 'https://www.ninjatech.ai/pricing#pay-per-use';
export const RATE_LIMIT = 'https://www.ninjatech.ai/pricing#rate-limits';

export const EXTERNAL_MODELS_LINK =
  'https://www.ninjatech.ai/product/external-model-access#all-models';

export const DEEP_RESEARCHER = 'https://www.ninjatech.ai#deep-researcher';
export const IMAGE_GEN = 'https://www.ninjatech.ai#image-gen';
export const CODER = 'https://www.ninjatech.ai#coder';
export const SCHEDULER = 'https://www.ninjatech.ai#scheduler';
export const WRITER = 'https://www.ninjatech.ai#writer';

export const ONBOARDING_IMAGE_MOBILE =
  'https://ninjatech-mainwebsite.s3.us-west-2.amazonaws.com/Mobile_Onboarding_Modal.jpg';

export const ONBOARDING_IMAGE_DESKTOP =
  'https://ninjatech-mainwebsite.s3.us-west-2.amazonaws.com/Desktop_Onboarding_Modal.jpg';

export const WELCOME_PRO_TRIAL_IMAGE =
  'https://ninjatech-mainwebsite.s3.us-west-2.amazonaws.com/Welcome_to_ninja_pro_trial.gif';

export const DESKTOP_APP_DOWNLOAD_LATEST =
  'https://api.github.com/repos/NinjaTech-AI/desktop-app/releases/latest';
export const DESKTOP_APP_RELEASES_LATEST =
  'https://github.com/NinjaTech-AI/desktop-app/releases/latest';

export const EXTERNAL_MODELS_IMAGE_DARK =
  'https://ninjatech-mainwebsite.s3.us-west-2.amazonaws.com/brand_logos_dark_mode.jpg';

export const EXTERNAL_MODELS_IMAGE_LIGHT =
  'https://ninjatech-mainwebsite.s3.us-west-2.amazonaws.com/brand_logos_light_mode.jpg';

export const LEARN_MORE_ADVANCED_CODING =
  'https://www.ninjatech.ai/news/ninjatech-ai-achieves-state-of-the-art-humaneval-benchmark-with-new-agentic-workflow-for-codegen';
