import { useContext, useMemo } from 'react';
import { Hash } from '@phosphor-icons/react';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import {
  useBreakpoint,
  useCurrentConversation,
  useLeftPanelState,
} from 'src/hooks';
import { AddConversationButton } from 'src/pages/ManageTasksChatPage/components/MultiConversationsListPanel/components/AddConversationButton';
import { MainNavToggleButton } from 'src/pages/ManageTasksChatPage/components/ConversationsHeader/components/MainNavToggleButton';
import { ModelsMenu } from 'src/pages/ManageTasksChatPage/components/ConversationsHeader/components/ModelsMenu';
import { UserMenu } from './components/UserMenu';
import styles from './ConversationsHeader.module.scss';
import { SVG_SIZE_M } from 'src/constants';
import { AvatarSelectorButton } from 'src/pages/ManageTasksChatPage/components/AvatarSelectorButton';

export const ConversationsHeader = () => {
  const { isMobileOrTablet, isDesktopAndUp } = useBreakpoint();
  const { currentConversation } = useCurrentConversation();
  const { isTasksListExpanded } = useLeftPanelState();

  const { isFullscreen } = useContext(AvatarModeContext);

  const headerTitle = useMemo(
    () => currentConversation?.conversation_hash?.replace('#', '') || '',
    [currentConversation],
  );


  return (
    <header className={styles.root}>
      <div className={styles.actionWrapper}>
        {!isFullscreen && !isTasksListExpanded && <MainNavToggleButton />}

        {!isMobileOrTablet && <AddConversationButton />}
      </div>

      {headerTitle && isDesktopAndUp && (
        <h4 className={styles.desktopTitle}>
          <Hash size={SVG_SIZE_M} />
          <span className={styles.center}>{headerTitle}</span>
        </h4>
      )}

      <div className={styles.actionWrapper}>
        <AvatarSelectorButton />
        <ModelsMenu />
        <UserMenu />

        {isMobileOrTablet && (
          <AddConversationButton className="nj-thread-header--mobile-create-new-chat" />
        )}
      </div>
    </header>
  );
};
