import styles from './TermsSection.module.scss';
import {
  DEFAULT_PRIVACY_POLICY_LINK,
  DEFAULT_TERMS_OF_SERVICE_LINK,
} from 'src/constants/externalLinks';

export const TermsSection = () => {
  return (
    <div className={styles.root}>
      By singing up, you agree to our&nbsp;
      <span className={styles.underlined}>
        <a
          href={DEFAULT_TERMS_OF_SERVICE_LINK}
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>
      </span>
      &nbsp;&amp;&nbsp;
      <span className={styles.underlined}>
        <a href={DEFAULT_PRIVACY_POLICY_LINK} target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
      </span>
      &nbsp;
    </div>
  );
};
