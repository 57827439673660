import { useState } from 'react';
import { TextModelList } from '../TextModelList';
import { ImageModelList } from '../ImageModelList';
import { useSession } from 'src/hooks';
import styles from './ModelLists.module.scss';
import { TabList, Tab } from '../TabList';

interface ModelListsProps {
  selectedTextModels: string[];
  selectedImageModels: string[];
  onTextModelClick: (model: string) => void;
  onImageModelClick: (model: string) => void;
}

export const ModelLists = ({
  selectedTextModels,
  selectedImageModels,
  onTextModelClick,
  onImageModelClick,
}: ModelListsProps) => {
  const { isFreeTier, isOpenTier, isStandardTier, isProTier, isProTrialTier } =
    useSession();

  const [activeTab, setActiveTab] = useState<Tab>('text');

  const isLimitedForPro = isFreeTier || isOpenTier || isStandardTier;
  const isLimitedForUltra = isProTier || isProTrialTier;

  return (
    <div className={styles.root}>
      <TabList
        activeTab={activeTab}
        textModelsLength={selectedTextModels.length}
        imageModelsLength={selectedImageModels.length}
        setActiveTab={setActiveTab}
      />

      <div className={styles.container}>
        {activeTab === 'text' && (
          <TextModelList
            isLimitedForPro={isLimitedForPro}
            isLimitedForUltra={isLimitedForUltra}
            selectedTextModels={selectedTextModels}
            onClick={onTextModelClick}
          />
        )}

        {activeTab === 'image' && (
          <ImageModelList
            isLimitedForPro={isLimitedForPro}
            isLimitedForUltra={isLimitedForUltra}
            selectedImageModels={selectedImageModels}
            onClick={onImageModelClick}
          />
        )}
      </div>
    </div>
  );
};
