import dayjs from 'dayjs';
import {
  Message,
  MessageType,
  ResearchTaskCreationCard,
  SchedulerCreationCard,
  ResearchCardData,
  CalendarCardEvent,
  isChitChatCard,
} from 'src/types';
import { flattenObject } from './conversation';
import { externalModelsSet } from 'src/constants';

/**
 * getSchedulerCopyTemplate() returns a copyable string template for a scheduler task creation card.
 * @param message Message
 * @returns string
 */
export function getSchedulerCopyTemplate(message: Message): string {
  if (message.message_type !== MessageType.SCHEDULER_TASK_CREATION_CARD) {
    return '';
  }

  const { calendar_card, details } = message?.payload as SchedulerCreationCard;
  const { summary = 'Schedule', dates } = calendar_card || {};

  const template: string[] = [];

  if (details) template.push(details || '');

  if (dates) {
    template.push(summary);
    template.push(`Calendar: https://calendar.google.com/calendar/u/0/r`);

    const sortedDates = Object.keys(dates).sort((a, b) =>
      dayjs(a).diff(dayjs(b)),
    );

    if (sortedDates.length > 0) {
      for (const sortedDate of sortedDates) {
        template.push(`\n${dayjs(sortedDate).format('MMM DD')}`);
      }

      template.push(dayjs(sortedDates[0]).format('dddd, MMM D, YYYY'));

      const sortedByDateEvents = sortedDates.reduce(
        (acc, date) => {
          const sortedEvents = dates[date]
            .slice()
            .sort((a, b) => dayjs(a.start).diff(dayjs(b.start)));
          acc[date] = sortedEvents;
          return acc;
        },
        {} as Record<string, Array<CalendarCardEvent>>,
      );

      const currentEvents = sortedByDateEvents[sortedDates[0]];

      for (const currentEvent of currentEvents) {
        const { start, end, is_all_day, title, is_recurring, html_link } =
          currentEvent;
        const timeRange = is_all_day
          ? 'All day'
          : `${dayjs(start).format('h:mm A')}-${dayjs(end).format('h:mm A')}`;

        template.push(
          `${timeRange} \t ${
            is_recurring ? 'recurring' : ''
          } \t ${title} \n ${html_link}`,
        );
      }
    }
  }

  return template.join('\n\n');
}

/**
 * getResearchTemplate() returns a copyable string template for a research task creation card.
 * @param message Message
 * @returns string
 */
export function getResearchCopyTemplate(message: Message): string {
  if (message.message_type !== MessageType.RESEARCH_TASK_CREATION_CARD) {
    return '';
  }

  const { research_card } = message?.payload as ResearchTaskCreationCard;

  const template: string[] = [];

  if (!research_card) {
    return template.join('\n');
  }

  const { search_result, deep_research_result, generative_ai_result } =
    research_card.data as ResearchCardData;

  const { web_page_references, image_references, video_references } =
    search_result || {};

  if (search_result) {
    template.push('Ninja LLM');
    template.push('Ninja');
    template.push(search_result?.summary ?? '');
  }

  if (web_page_references) {
    template.push('References');
    template.push(flattenObject({ web_page_references: web_page_references }));
  }

  if (deep_research_result) {
    template.push('Ninja SuperAgent');
    template.push(deep_research_result?.summary ?? '');
  }

  if (image_references || video_references) {
    const combined_references = [
      ...(video_references || []),
      ...(image_references || []),
    ];

    template.push('Videos & Images');
    if (Array.isArray(combined_references) && combined_references.length > 0) {
      template.push(
        flattenObject({ combined_references: combined_references }),
      );
    }
  }

  if (generative_ai_result) {
    const { referenced_model_summary } = generative_ai_result || {};
    const { references, summary } = referenced_model_summary || {};

    if (references) {
      const sources = Object.values(references)
        .map((value) => {
          const { model, content } = value;
          const name = model ? externalModelsSet[model].display_name : '';

          return {
            key: name,
            content,
          };
        })
        .slice();

      const otherSources = sources.map((source) => source.key);
      template.push('From other sources:', otherSources.join(', '));

      template.push(summary ?? '');

      for (const value of sources) {
        template.push(flattenObject(value));
      }
    }
  }

  return template.join('\n\n');
}

export function getChatCardCopyTemplate(message: Message): string {
  if (
    message.message_type !== MessageType.CHAT_CARD ||
    !isChitChatCard(message.payload)
  ) {
    return '';
  }

  const { data } = message.payload;
  const { ninja_model_output, referenced_model_summary } = data || {};

  const template: string[] = [];

  if (ninja_model_output) {
    template.push(ninja_model_output);
  }

  if (!referenced_model_summary) {
    return template.join('\n');
  }

  const { summary, references } = referenced_model_summary;

  if (references) {
    const sources = Object.values(references)
      .map((value) => {
        const { model, content } = value;
        const name = model ? externalModelsSet[model].display_name : '';

        return {
          key: name,
          content,
        };
      })
      .slice();

    const otherSources = sources.map((source) => source.key);
    template.push('From other sources:', otherSources.join(', '));

    template.push(summary ?? '');

    for (const value of sources) {
      template.push(flattenObject(value));
    }
  }

  return template.join('\n\n');
}
