import classNames from 'classnames';
import { StreamingText } from 'src/components/StreamingText';
import { Citation } from 'src/types';

interface ThreadMessageContentProps {
  content: string;
  className?: string;
  isStreaming?: boolean;
  isExpanded?: boolean;
  references?: Citation[];
}

export const ThreadMessageContent = ({
  content,
  className,
  isStreaming,
  isExpanded,
  references,
}: ThreadMessageContentProps) => {
  if (!content) {
    return null;
  }

  return (
    <div className={classNames('nj-thread-message--content', className)}>
      <StreamingText
        isStreaming={isStreaming}
        content={content}
        isExpanded={isExpanded}
        references={references}
      />
    </div>
  );
};
