import { Button } from 'src/v2/commonComponents/Button';
import styles from './ExternalModelItem.module.scss';
import { Check } from '@phosphor-icons/react';
import { SVG_SIZE_M } from 'src/constants';
import { NinjaSubscriptionPlan } from 'src/types';
import { LabelPro } from 'src/components/LabelPro';
import { LabelUltra } from 'src/components/LabelUltra';
import LabelStandard from 'src/components/LabelStandard/LabelStandard';
import classNames from 'classnames';

export interface ExternalModelItemProps {
  id: string;
  icon: JSX.Element;
  title: string;
  selected?: boolean;
  included?: boolean;
  availableIn?: NinjaSubscriptionPlan;
  onClick?: (id: string) => void;
}

export const ExternalModelItem = ({
  id,
  icon,
  title,
  selected,
  included,
  availableIn,
  onClick,
}: ExternalModelItemProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  return (
    <Button
      disabled={!!availableIn}
      onClick={handleClick}
      className={classNames(styles.root, {
        [styles.withoutCursor]: included,
      })}
      color="transparent"
    >
      {icon}

      <span className={styles.title}>
        {title}
        {availableIn === NinjaSubscriptionPlan.PRO && <LabelPro />}
        {availableIn === NinjaSubscriptionPlan.ULTRA && <LabelUltra />}
        {availableIn === NinjaSubscriptionPlan.STANDARD && <LabelStandard />}
      </span>

      {included ? (
        <span className={styles.caption}>Included</span>
      ) : (
        selected && <Check size={SVG_SIZE_M} />
      )}
    </Button>
  );
};
