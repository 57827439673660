import { useNavigate } from 'react-router-dom';
import { AppRoutes, GTMEvent } from 'src/types';
import styles from './ChangePlanButton.module.scss';
import { sendGTMEvent } from 'src/utils';
import { useSession, useSubscriptionInfo } from 'src/hooks';
import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';

export const ChangePlanButton = () => {
  const navigate = useNavigate();
  const { isEnterpriseTrialTier } = useSession();
  const { onToggleVisibleContactNinjaModal } = useContext(SessionContext);

  const {
    subscriptionInfo,
    isSubscriptionInfoLoading,
    isSubscriptionInfoFetching,
  } = useSubscriptionInfo();

  const handleChangeButtonClick = () => {
    if (!isEnterpriseTrialTier) {
      navigate(AppRoutes.PAYMENT);
    } else {
      onToggleVisibleContactNinjaModal(true);
    }
    sendGTMEvent(GTMEvent.CHANGE_SUBSCRIPTION_CLICK);
  };

  // const upcomingPaymentDate = dayjs(
  //   subscriptionInfo?.curr_period_end_timestamp,
  // ).format(PAYMENTS_DATE_FORMAT);

  return (
    <div className={styles.root}>
      <button
        type="button"
        className={styles.button}
        onClick={handleChangeButtonClick}
        disabled={
          isSubscriptionInfoLoading ||
          isSubscriptionInfoFetching ||
          subscriptionInfo?.has_scheduled_downgrade ||
          subscriptionInfo?.is_cancelled ||
          subscriptionInfo?.has_scheduled_plan_change ||
          subscriptionInfo?.processing_plan_change
        }
      >
        Change plan
      </button>
      {(subscriptionInfo?.has_scheduled_downgrade ||
        subscriptionInfo?.has_scheduled_plan_change ||
        subscriptionInfo?.is_cancelled) && (
        <label className={styles.changePlanLabel}>
          Update unavailable: Changes pending at the end of billing cycle
        </label>
      )}
      {/*todo temporary hide this*/}
      {/*{(subscriptionInfo?.has_scheduled_downgrade ||*/}
      {/*  subscriptionInfo?.has_scheduled_plan_change ||*/}
      {/*  subscriptionInfo?.processing_plan_change) &&*/}
      {/*  !subscriptionInfo?.is_cancelled &&*/}
      {/*  subscriptionInfo?.scheduled_plan_name === appUser?.tier_id && (*/}
      {/*    <label className={styles.changePlanLabel}>*/}
      {/*      Change plan will be available on {upcomingPaymentDate}*/}
      {/*    </label>*/}
      {/*  )}*/}
      {/*{subscriptionInfo?.is_cancelled && (*/}
      {/*  <label className={styles.cancelledLabel}>*/}
      {/*    {SUBSCRIPTION_PENDING_CHANGES_NOTIFICATION_MESSAGE}*/}
      {/*  </label>*/}
      {/*)}*/}
    </div>
  );
};
