import classNames from 'classnames';
import { Button } from 'src/v2/commonComponents/Button';
import styles from './TabList.module.scss';

export type Tab = 'text' | 'image';

interface TabListProps {
  activeTab: Tab;
  textModelsLength: number;
  imageModelsLength: number;
  setActiveTab: (tab: Tab) => void;
}

export const TabList = ({
  activeTab,
  textModelsLength,
  imageModelsLength,
  setActiveTab,
}: TabListProps) => {
  const tabs: {
    tabType: Tab;
    title: string;
    modelsLength: number;
  }[] = [
    {
      tabType: 'text',
      title: 'Text',
      modelsLength: textModelsLength,
    },
    {
      tabType: 'image',
      title: 'Image',
      modelsLength: imageModelsLength,
    },
  ];

  return (
    <div className={styles.root}>
      {tabs.map(({ tabType, title, modelsLength }) => (
        <Button
          key={tabType}
          color="transparent"
          className={classNames(styles.tab, {
            [styles.active]: activeTab === tabType,
          })}
          onClick={() => setActiveTab(tabType)}
        >
          {`${title} (${modelsLength}/2)`}
        </Button>
      ))}
    </div>
  );
};
