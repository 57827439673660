import { useState, createContext } from 'react';
import {
  TasksListSortByStatus,
  TasksListSortByTime,
  ChatsFilter,
  ModalGalleryType,
  ModalGalleryList,
} from 'src/types';

export type SessionContextType = {
  currentScrollingTaskId: string | null;
  isOpenTaskListControlsPanel: boolean;
  sortedTasksByStatus: TasksListSortByStatus;
  sortedTasksByTime: TasksListSortByTime;
  isVisibleTaskViewModal: boolean;
  isVisibleModalGallery: boolean;
  isVisiblePlanCardModal: boolean;
  isOpenContactNinjaModal: boolean;
  chatsFilter?: ChatsFilter;
  pageToken: string;
  filteredTasksPageToken: string;
  isShowAvatarSelect: boolean;
  isVisibleUpsellAvatarModal: boolean;
  onToggleAvatarSelect: (value?: boolean) => void;
  onOpenTaskListControlsPanel: () => void;
  onCloseTaskListControlsPanel: () => void;
  setCurrentScrollingTaskId: (value: string | null) => void;
  setSortedTasksByStatus: (value: TasksListSortByStatus) => void;
  setSortedTasksByTime: (value: TasksListSortByTime) => void;
  onToggleVisibleTaskViewModal: (value?: boolean) => void;
  onTogglePlanCardModal: (value?: boolean) => void;
  onToggleVisibleContactNinjaModal: (value?: boolean) => void;
  onToggleUpsellAvatarModal: (value?: boolean) => void;
  setChatsFilter: (chatsFilter?: ChatsFilter) => void;
  setPageToken: (pageToken: string) => void;
  setFilteredTasksPageToken: (filteredTasksPageToken: string) => void;
  isVisibleUpdatePlanModal: boolean;
  onChangeIsVisibleUpdatePlanModal: (value: boolean) => void;
  onChangeModalGalleryData: (
    isShow: boolean,
    modalType: ModalGalleryType | null,
    data: ModalGalleryList,
    defaultSelectedIndex: number,
    taskId?: string,
  ) => void;
  currentModalGalleryType: ModalGalleryType | null;
  currentListOfGalleryItems: ModalGalleryList;
  defaultSelectedGalleryIndex: number;
  isOpenSupportModal: boolean;
  onChangeIsOpenSupportModal: (value: boolean) => void;
  currentTaskId?: string;
  isOpenCancelPaypalSubscriptionModal: boolean;
  onToggleVisibleCancelPaypalSubscriptionModal: (value?: boolean) => void;
};

type SessionContextProviderProps = {
  children: React.ReactNode;
};

// TODO(olha): needs refactoring and moving to the slice
const SessionContext = createContext<SessionContextType>({
  currentScrollingTaskId: null,
  isOpenTaskListControlsPanel: false,
  sortedTasksByStatus: TasksListSortByStatus.ALL,
  sortedTasksByTime: TasksListSortByTime.DEFAULT,
  isVisibleTaskViewModal: false,
  isVisibleModalGallery: false,
  isVisiblePlanCardModal: false,
  chatsFilter: ChatsFilter.ALL,
  pageToken: '',
  filteredTasksPageToken: '',
  isShowAvatarSelect: false,
  isVisibleUpsellAvatarModal: false,
  onToggleAvatarSelect: () => undefined,
  onOpenTaskListControlsPanel: () => undefined,
  onCloseTaskListControlsPanel: () => undefined,
  setCurrentScrollingTaskId: () => undefined,
  setSortedTasksByStatus: () => undefined,
  setSortedTasksByTime: () => undefined,
  onToggleVisibleTaskViewModal: () => undefined,
  onTogglePlanCardModal: () => undefined,
  onToggleUpsellAvatarModal: () => undefined,
  setChatsFilter: () => undefined,
  setPageToken: () => undefined,
  setFilteredTasksPageToken: () => undefined,
  isVisibleUpdatePlanModal: false,
  onChangeIsVisibleUpdatePlanModal: () => undefined,
  onChangeModalGalleryData: () => undefined,
  currentModalGalleryType: null,
  currentListOfGalleryItems: [],
  defaultSelectedGalleryIndex: 0,
  isOpenSupportModal: false,
  onChangeIsOpenSupportModal: () => undefined,
  isOpenContactNinjaModal: false,
  onToggleVisibleContactNinjaModal: () => undefined,
  isOpenCancelPaypalSubscriptionModal: false,
  onToggleVisibleCancelPaypalSubscriptionModal: () => undefined,
});

const SessionContextProvider = ({ children }: SessionContextProviderProps) => {
  const [isShowAvatarSelect, setIsShowAvatarSelect] = useState<boolean>(false);
  const [isVisibleUpdatePlanModal, onChangeIsVisibleUpdatePlanModal] =
    useState<boolean>(false);
  const [defaultSelectedGalleryIndex, setDefaultSelectedGalleryIndex] =
    useState(0);
  const [isOpenSupportModal, onChangeIsOpenSupportModal] =
    useState<boolean>(false);
  const [isOpenContactNinjaModal, onChangeIsOpenContactNinjaModal] =
    useState<boolean>(false);
  const [
    isOpenCancelPaypalSubscriptionModal,
    onChangeIsOpenCancelPaypalSubscriptionModal,
  ] = useState<boolean>(false);

  const [isVisibleModalGallery, setIsVisibleModalGallery] =
    useState<boolean>(false);
  const [currentModalGalleryType, setCurrentModalGalleryType] =
    useState<ModalGalleryType | null>(null);
  const [currentListOfGalleryItems, setCurrentListOfGalleryItems] =
    useState<ModalGalleryList>([]);
  const [taskId, setTaskId] = useState<string | undefined>();

  const handleChangeModalGallery = (
    isShowModal: boolean,
    modalContentType: ModalGalleryType | null,
    listOfItems: ModalGalleryList,
    defaultSelectedGalleryIndex: number,
    taskId?: string,
  ) => {
    setCurrentListOfGalleryItems(listOfItems);
    setDefaultSelectedGalleryIndex(defaultSelectedGalleryIndex);
    setCurrentModalGalleryType(modalContentType);
    setIsVisibleModalGallery(isShowModal);
    if (taskId) {
      setTaskId(taskId);
    }
  };

  const [isVisibleTaskListControlsPanel, setVisibleTaskListControlsPanel] =
    useState<boolean>(false);

  const handleOpenTaskListControlsPanel = () => {
    setVisibleTaskListControlsPanel(true);
  };

  const handleCloseTaskListControlsPanel = () => {
    setVisibleTaskListControlsPanel(false);
  };

  const [currentScrollingTaskId, setCurrentScrollingTaskId] = useState<
    string | null
  >(null);

  const [sortedTasksByTime, setSortedTasksByTime] =
    useState<TasksListSortByTime>(TasksListSortByTime.DEFAULT);
  const [sortedTasksByStatus, setSortedTasksByStatus] =
    useState<TasksListSortByStatus>(TasksListSortByStatus.ALL);

  const [isVisibleTaskViewModal, serVisibleTaskViewModal] =
    useState<boolean>(false);

  const onToggleVisibleTaskViewModal = (value?: boolean) => {
    serVisibleTaskViewModal(value ?? !isVisibleTaskViewModal);
  };

  const [isVisiblePlanCardModal, setVisiblePlanCardModal] =
    useState<boolean>(false);

  const onTogglePlanCardModal = (value?: boolean) => {
    setVisiblePlanCardModal(value ?? !isVisiblePlanCardModal);
  };

  const [pageToken, setPageToken] = useState<string>('');

  const onChangePageToken = (pageToken: string) => {
    setPageToken(pageToken);
  };

  const [filteredTasksPageToken, setFilteredTasksPageToken] =
    useState<string>('');

  const onChangeFilteredTasksPageToken = (filteredTasksPageToken: string) => {
    setFilteredTasksPageToken(filteredTasksPageToken);
  };

  const [chatsFilter, setChatsFilter] = useState<ChatsFilter>(ChatsFilter.ALL);

  const onChangeChatsFilter = (chatsFilter?: ChatsFilter) => {
    setPageToken('');
    setFilteredTasksPageToken('');

    setChatsFilter(chatsFilter ?? ChatsFilter.ALL);
  };

  const handleToggleAvatarSelect = (value?: boolean) => {
    setIsShowAvatarSelect(value ?? !isShowAvatarSelect);
  };

  const [isVisibleUpsellAvatarModal, setVisibleUpsellAvatarModal] =
    useState<boolean>(false);

  const handleToggleUpsellAvatarModal = (value?: boolean) => {
    setVisibleUpsellAvatarModal(value ?? !isVisibleUpsellAvatarModal);
  };

  const onToggleVisibleContactNinjaModal = (value?: boolean) => {
    onChangeIsOpenContactNinjaModal(value ?? !isOpenContactNinjaModal);
  };

  const onToggleVisibleCancelPaypalSubscriptionModal = (value?: boolean) => {
    onChangeIsOpenCancelPaypalSubscriptionModal(
      value ?? !isOpenCancelPaypalSubscriptionModal,
    );
  };

  return (
    <SessionContext.Provider
      value={{
        currentScrollingTaskId,
        isOpenTaskListControlsPanel: isVisibleTaskListControlsPanel,
        sortedTasksByStatus,
        sortedTasksByTime,
        isVisibleTaskViewModal,
        isVisibleModalGallery,
        isVisiblePlanCardModal,
        chatsFilter,
        pageToken,
        filteredTasksPageToken,
        isShowAvatarSelect,
        isVisibleUpsellAvatarModal,
        onToggleAvatarSelect: handleToggleAvatarSelect,
        onOpenTaskListControlsPanel: handleOpenTaskListControlsPanel,
        onCloseTaskListControlsPanel: handleCloseTaskListControlsPanel,
        setCurrentScrollingTaskId,
        setSortedTasksByStatus,
        setSortedTasksByTime,
        onToggleVisibleTaskViewModal,
        onTogglePlanCardModal,
        setChatsFilter: onChangeChatsFilter,
        setPageToken: onChangePageToken,
        setFilteredTasksPageToken: onChangeFilteredTasksPageToken,
        isVisibleUpdatePlanModal,
        onChangeIsVisibleUpdatePlanModal,
        onChangeModalGalleryData: handleChangeModalGallery,
        currentModalGalleryType,
        currentListOfGalleryItems,
        defaultSelectedGalleryIndex,
        onToggleUpsellAvatarModal: handleToggleUpsellAvatarModal,
        isOpenSupportModal,
        onChangeIsOpenSupportModal,
        currentTaskId: taskId,
        onToggleVisibleContactNinjaModal,
        isOpenContactNinjaModal,
        onToggleVisibleCancelPaypalSubscriptionModal,
        isOpenCancelPaypalSubscriptionModal,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export { SessionContextProvider };
export default SessionContext;
