import styles from './AvailableDivider.module.scss';

interface AvailableDividerProps {
  withPro?: boolean;
}

export const AvailableDivider = ({ withPro }: AvailableDividerProps) => {
  return (
    <span className={styles.root}>
      {`Available in ${withPro === false ? 'Pro & ' : ''}Ultra`}
    </span>
  );
};
