import {
  ChitChatCard,
  ConversationRole,
  Message,
  MessageType,
  ResearchTaskCreationCard,
  SchedulerCreationCard,
  ImageCard,
} from 'src/types';
import { ThreadMessageContent } from '../../../../../components/FlatAppearance/components/ThreadMessageContent';
import { ThreadScheduleCard } from '../../../../../components/FlatAppearance/components/ThreadScheduleCard';
import { DebugMessageWrapper } from 'src/pages/ManageTasksChatPage/components/TaskScratchpad/components/DebugMessageWrapper';
import { ThreadErrorMessageContent } from 'src/components/FlatAppearance/components/ThreadErrorMessageContent';
import { ErrorPayload } from 'src/types/models/ErrorPayload';
import { ThreadImageGenerationCard } from 'src/components/FlatAppearance/components/ThreadImageGenerationCard';
import { ThreadResearchCard } from 'src/v2/components/ThreadMessage/components/ThreadResearchCard';
import { ThreadChitChatCard } from 'src/v2/components/ThreadMessage/components/ThreadChitChatCard';

interface ThreadMessageBodyProps {
  message: Message;
}

export const ThreadMessageBody = ({ message }: ThreadMessageBodyProps) => {
  const { message_type, payload, is_final_answer, role } = message;

  const shouldStreaming = !is_final_answer && role !== ConversationRole.USER;

  const getCardToDisplay = () => {
    if (!payload) {
      return null;
    }

    switch (message_type) {
      case MessageType.RESEARCH_TASK_CREATION_CARD:
        return (
          <ThreadResearchCard
            researchCard={(payload as ResearchTaskCreationCard).research_card}
            // TODO(olha): this is a workaround! needs to change condition and moves to child components to avoid chain of props!!!
            isStreaming={shouldStreaming}
          />
        );
      case MessageType.SCHEDULER_TASK_CREATION_CARD:
        return (
          <ThreadScheduleCard
            calendarCard={(payload as SchedulerCreationCard).calendar_card}
            details={(payload as SchedulerCreationCard).details}
            //TODO(olha): add prop from BE
            summary=""
          />
        );
      case MessageType.CODE_TASK_CREATION_CARD:
        return (
          <ThreadChitChatCard
            chitChatCard={payload as ChitChatCard}
            isStreaming={shouldStreaming}
          />
        );
      case MessageType.ERROR_MESSAGE:
        return (
          <ThreadErrorMessageContent
            content={message.content}
            errorCode={(payload as ErrorPayload).error_code}
            unsupportedSkills={(payload as ErrorPayload).unsupported_skills}
          />
        );
      case MessageType.CHAT_CARD:
        return (
          <ThreadChitChatCard
            chitChatCard={payload as ChitChatCard}
            isStreaming={shouldStreaming}
          />
        );
      case MessageType.IMAGE_CARD:
        return (
          <ThreadImageGenerationCard
            imageCard={payload as ImageCard}
            messageOriginalQuery={message.original_query}
            messageCleanQuery={message.clean_query}
          />
        );
      default:
        return (
          <ThreadMessageContent
            content={message.content}
            isStreaming={shouldStreaming}
          />
        );
    }
  };

  return (
    <DebugMessageWrapper message={message}>
      {getCardToDisplay()}
    </DebugMessageWrapper>
  );
};
